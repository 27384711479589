import { Component, Inject, ChangeDetectorRef, OnInit } from "@angular/core";
import { AuthenticationService } from "../../services/authentication.service";
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatDialog } from '@angular/material/dialog';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { faPlus, faEdit, faBook, faClone, faCog } from '@fortawesome/free-solid-svg-icons';
import { Survey } from '../../interfaces/survey';
import { DynamicContent } from '../../interfaces/dynamicContent';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { SurveyFlagDetailsDialogComponent } from './surveyflag.details.dialog.component';
import { PercentPipe } from "@angular/common";

@Component({
    selector: 'survey-list',
    templateUrl: './survey-list.component.html',
    styleUrls: ['./survey-list.component.less']
})

export class SurveyListComponent implements OnInit {
  isLoggedIn = false;
  surveysLoaded = false;
  showArchived = false;
  surveys: Survey[] = [];
  dynamicContent: DynamicContent;
  dynamicTitleContent: DynamicContent;
  safeDynamicContent: SafeHtml;
  safeDynamicTitleContent: SafeHtml;
  canAddSurvey = false;
  faPlus = faPlus;
  faEdit = faEdit;
  faBook = faBook;
  faClone = faClone;
  faCog = faCog;
  surveysDataSource: MatTableDataSource<Survey> = new MatTableDataSource<Survey>([]);
  displayedColumns: string[] = ['actions', 'survey', 'description'];

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject('BASE_URL') private baseUrl: string) {
    this.canAddSurvey = authService.isAdmin() && authService.hasFlag('a20f0ccb-e655-410c-9aaa-e272532c4d65');
    this.isLoggedIn = authService.currentUserValue !== null;
    if (authService.currentUserValue) {
      this.isLoggedIn = authService.currentUserValue !== null;
    }
    if (authService.isAdmin()) {
      this.displayedColumns = ['actions', 'survey', 'version', 'description'];
    }
  }

  ngOnInit() {
    let url = this.baseUrl + "api/survey/anonymous";
    if (this.isLoggedIn) {
      url = this.baseUrl + "api/survey/user/" + this.authService.currentUserValue.id.toString();
    }
    this.http.get<Survey[]>(url).subscribe(result => {
      this.surveys = result;
      this.surveysLoaded = true;
      this.showData();
      this.changeDetectorRef.detectChanges();
    }, error => {
      console.error(error)
    });

    this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/Home%20Title").subscribe(result => {
      this.dynamicTitleContent = result;
      this.safeDynamicTitleContent = this.sanitizer.bypassSecurityTrustHtml(result.content);
    }, error => {
      console.error(error)
      this.dynamicTitleContent = {} as DynamicContent;
      this.dynamicTitleContent.content = "An error occurred attempting to load content for this page.";
      this.safeDynamicTitleContent = this.sanitizer.bypassSecurityTrustHtml(this.dynamicTitleContent.content);
    });

    this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/Home%20Body").subscribe(result => {
      this.dynamicContent = result;
      this.safeDynamicContent = this.sanitizer.bypassSecurityTrustHtml(result.content);
    }, error => {
      console.error(error)
      this.dynamicContent = {} as DynamicContent;
      this.dynamicContent.content = "An error occurred attempting to load content for this page.";
      this.safeDynamicContent = this.sanitizer.bypassSecurityTrustHtml(this.dynamicContent.content);
    });
  }

  onCreate() {
    console.log("Add assessment has been selected.");
    this.router.navigate(["/survey/create"]);
  }

  onSelect(survey: Survey) {
    console.log("assessment with Id " + survey.id + " (version " + survey.surveyVersionId + ") has been selected.");
    this.router.navigate(["/survey", survey.surveyVersionId]);
  }

  onSettings(survey: Survey) {
    console.log("assessment with Id " + survey.id + " (version " + survey.surveyVersionId + ") has been selected for changing settings.");

    // Are you sure?
    const dialogRef = this.dialog.open(SurveyFlagDetailsDialogComponent, {
      data: {
        id: survey.id,
        isAlwaysAvailable: survey.isAlwaysAvailable,
        isAvailableWithoutLogin: survey.isAvailableWithoutLogin,
        showSurveySummary: survey.showSurveySummary,
        showSummaryFlags: survey.showSummaryFlags,
        showGrpScore: survey.showGrpScore,
        isArchived: survey.isArchived,
        hasRoleAccess: survey.hasRoleAccess,
        accessRoles: survey.accessRoles,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      // Update the survey in memory.
      if (data) {
        const survey: Survey[] = this.surveys.filter(x => x.id == data.id);
        if (survey.length == 1) {
          survey[0].isAlwaysAvailable = data.isAlwaysAvailable;
          survey[0].isAvailableWithoutLogin = data.isAvailableWithoutLogin;
          survey[0].isArchived = data.isArchived;
          survey[0].showGrpScore = data.showGrpScore;
          survey[0].showSummaryFlags = data.showSummaryFlags;
          survey[0].showSurveySummary = data.showSurveySummary;
          survey[0].accessRoles = data.accessRoles;
          survey[0].hasRoleAccess = data.hasRoleAccess;
         }
      }
    });
  }

  onClone(survey: Survey) {
    console.log("assessment with Id " + survey.id + " (version " + survey.surveyVersionId + ") has been selected for cloning.");

    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to clone this assessment?",
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const url = this.baseUrl + "api/survey/clone";

        this.http.post<Survey>(url, survey)
          .subscribe(result => {
            this.surveys.push(result);
            this.showData();
          },
            error => console.error("Unable to clone the assessment."));
      }
    });
  }

  onPublish(survey: Survey) {
    console.log("assessment with Id " + survey.id + " (version " + survey.surveyVersionId + ") has been selected for publishing.");

    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to publish this assessment version?",
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const url = this.baseUrl + "api/survey/publish";

        this.http.post<Survey>(url, survey)
          .subscribe(result => {
            survey.publishedDate = result.publishedDate;
          },
            error => console.error("Unable to publish the assessment."));
        }
    });
  }

  onVersion(survey: Survey) {
    console.log("assessment with Id " + survey.id + " (version " + survey.surveyVersionId + ") has been selected for versioning.");

    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to create a new version of this assessment?",
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const url = this.baseUrl + "api/survey/version";

        this.http.post<Survey>(url, survey)
          .subscribe(result => {
            console.log("assessment with Id " + survey.id + " (version " + survey.surveyVersionId + ") has been selected.");
            this.router.navigate(["/survey", result.surveyVersionId]);
          },
            error => console.error("Unable to version the assessment."));
      }
    });
  }

  onListDrop(event: CdkDragDrop<Survey[]>) {
    // Swap the elements around
    const prevIndex = this.surveys.findIndex((d) => d === event.item.data);
    moveItemInArray(this.surveys, prevIndex, event.currentIndex);
    this.showData();

    // Update the display order
    this.surveys.map(x => {
      x.displayOrder = this.surveys.findIndex(question => question.id === x.id) + 1;
      return x
    });

    // Save changes to the server
    this.http.post<any>(`api/survey/order`, this.surveys.map(x => {
      return { DisplayOrder: x.displayOrder, Id: x.id }
    })).subscribe(result => {
      console.log("POST is successful ", result);
    },
      error => console.error(error));
  }


  toggleShowArchived(): void {
    this.showArchived = !this.showArchived;
    this.showData();
  }

  showData() {
    if (this.showArchived) {
      this.surveysDataSource = new MatTableDataSource<Survey>(this.surveys);
    }
    else {
      this.surveysDataSource = new MatTableDataSource<Survey>(this.surveys.filter(x => x.isArchived != true));
    }
  }
}
