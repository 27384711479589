import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HttpClient } from "@angular/common/http";
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserType } from '../../interfaces/usertype';
import { Survey } from '../../interfaces/survey';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'survey-flag-details-dialog',
  templateUrl: './surveyflag.details.dialog.component.html',
  styleUrls: ['./surveyflag.details.dialog.component.css']
})

export class SurveyFlagDetailsDialogComponent implements OnInit {
  form: FormGroup;
  title = "Assessment Flags";
  data: Survey;
  userTypes: UserType[] = [];

  get accessRolesFormArray() {
    return this.form.controls.accessRoles as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SurveyFlagDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data : Survey,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog) {
    this.data = data;
    this.form = this.formBuilder.group({
      isAlwaysAvailable:  data.isAlwaysAvailable,
      isAvailableWithoutLogin: data.isAvailableWithoutLogin,
      showSurveySummary: data.showSurveySummary,
      showSummaryFlags: data.showSummaryFlags,
      showGrpScore: data.showGrpScore,
      isArchived: data.isArchived,
      enableRoleAccess: data?.hasRoleAccess,
      accessRoles: new FormArray([]),
    });
  }

  ngOnInit() {
    const url = this.baseUrl + "api/usertype";
    this.http.get<UserType[]>(url).subscribe(result => {
      // Populate checklist
      this.userTypes = result;
      this.userTypes.forEach((role) => this.accessRolesFormArray.push(new FormControl(this.isRoleChecked(role.id))));
     },
      error => {
        // Turn off toggle
        console.error(error);
      });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  openDialog() {
    const dialogRef = this.dialog.open(SurveyFlagDetailsDialogComponent, {
      disableClose: true,
      autoFocus: true,
      data: {},
    });

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }

  close() {
    this.dialogRef.close(this.data);
  }

  toggleIsAlwaysAvailable(event: MatCheckboxChange): void {
    let message = "Are you sure you want to make this assessment available to everyone?";
    if (event.checked === false) {
      message = "Are you sure you want to make this assessment available to only assigned users?";
    }
    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Update the database
        this.http.put(this.baseUrl + `api/survey/available`, { id: this.data.id, isAlwaysAvailable: event.checked }).subscribe(() => {
          this.data.isAlwaysAvailable = event.checked;
        }, error => {
          console.error(error)
        });
      }
      else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  toggleReadOnly(event: MatCheckboxChange): void {
    let message = "Are you sure you want to make this assessment available to users without logging in?";
    if (event.checked === false) {
      message = "Are you sure you want to make this assessment available to users who are logged in?";
    }
    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });


    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Update the database
        this.http.put(this.baseUrl + `api/survey/readonly`, { id: this.data.id, isAvailableWithoutLogin: event.checked }).subscribe(() => {
          this.data.isAvailableWithoutLogin = event.checked;
        }, error => {
          console.error(error)
        });
      }
      else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  toggleArchived(event: MatCheckboxChange): void {
    let message = "Are you sure you want to archive this assessment?";
    if (event.checked === false) {
      message = "Are you sure you want to un-archive this assessment?";
    }
    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Update the database
        this.http.put(this.baseUrl + `api/survey/archive`, { id: this.data.id, isArchived: event.checked }).subscribe(() => {
          this.data.isArchived = event.checked;
        }, error => {
          console.error(error)
        });
      }
      else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  toggleSummary(event: MatCheckboxChange): void {
    let message = "Are you sure you want to show the assessment summary?";
    if (event.checked === false) {
      message = "Are you sure you do not want to show the assessment summary?";
    }
    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Update the database
        this.http.put(this.baseUrl + `api/survey/summary`, { id: this.data.id, showSurveySummary: event.checked }).subscribe(() => {
          this.data.showSurveySummary = event.checked;
        }, error => {
          console.error(error)
        });
      }
      else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  toggleValueFlags(event: MatCheckboxChange): void {
    let message = "Are you sure you want to make this assessment's value flags visible in the summary report?";
    if (event.checked === false) {
      message = "Are you sure you want to make this assessment's value flags invisible in the summary report?";
    }
    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Update the database
        this.http.put(this.baseUrl + `api/survey/valueflags`, { id: this.data.id, showValueFlags: event.checked }).subscribe(() => {
          this.data.showSummaryFlags = event.checked;
        }, error => {
          console.error(error)
        });
      }
      else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  toggleGrpScore(event: MatCheckboxChange): void {
    let message = "Are you sure you want to make this assessment's overall score visible in the summary report?";
    if (event.checked === false) {
      message = "Are you sure you want to make this assessment's overall score invisible in the summary report?";
    }
    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });


    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Update the database
        this.http.put(this.baseUrl + `api/survey/grpscore`, { id: this.data.id, showGrpScore: event.checked }).subscribe(() => {
          this.data.showGrpScore = event.checked;
        }, error => {
          console.error(error)
        });
      }
      else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  toggleHasRoleAccess(event: MatSlideToggleChange): void {
    let message = "Are you sure you want to allow access to this assessment dependent on the user's role?";
    if (event.checked === false) {
      message = "Are you sure you want to allow access to this accessment regardless of the user's role?";
    }
    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Update the database
        this.http.put(this.baseUrl + `api/survey/roleAccess`, { id: this.data.id, hasRoleAccess: event.checked }).subscribe(() => {
          this.data.hasRoleAccess = event.checked;
        }, error => {
          console.error(error)
        });
      }
      else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  toggleHasRoleChecked(event: MatCheckboxChange): void {
    let message = "Are you sure you want to allow the role to access to this assessment?";
    if (event.checked === false) {
      message = "Are you sure you want to prevent the role from accessing this accessment?";
    }
    // Are you sure?
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Update the database
        this.http.put(this.baseUrl + `api/survey/roleAccess`, { id: this.data.id, hasRoleAccess: event.checked }).subscribe(() => {
          this.data.hasRoleAccess = event.checked;
        }, error => {
          console.error(error)
        });
      }
      else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  toggleRoleChecked(): void {
    // Update the database
    const selectedUserTypeIds = this.form.value.accessRoles
      .map((v, i) => v ? this.userTypes[i].id : null)
      .filter(v => v !== null);

    this.http.put(this.baseUrl + `api/survey/accessRoles`, { id: this.data.id, accessRoles: JSON.stringify(selectedUserTypeIds) }).subscribe((checked) => {
      this.data.accessRoles = JSON.stringify(selectedUserTypeIds);
    }, error => {
      console.error(error)
    });
  }

  isRoleChecked(id: number): boolean {
    if (!this.data?.accessRoles || this.data?.accessRoles.length == 0) {
      return false;
    }

    const checked = JSON.parse(this.data.accessRoles);
    if (!checked) {
      return false;
    }

    if (!Array.isArray(checked)) {
      return false;
    }

    const checkArray: any[] = checked;
    return checkArray.some(x => x == id);
  }
}
