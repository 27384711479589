import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { AuthenticationService } from "../../services/authentication.service";
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { TargetAudienceDetailsDialogComponent } from '../target-audience/target-audience-details-dialog.component';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TargetAudience } from '../../interfaces/targetaudience';

@Component({
  selector: 'app-target-audience-list',
  templateUrl: './target-audience-list.component.html',
  styleUrls: ['./target-audience-list.component.css']
})

export class TargetAudienceListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'actions'];
  targetAudiences: TargetAudience[];
  datasource: MatTableDataSource<TargetAudience>;
  isAdminUser = false;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const url = this.baseUrl + "api/targetaudience";
    this.http.get<TargetAudience[]>(url).subscribe(result => {
      this.targetAudiences = result;
      this.datasource = new MatTableDataSource<TargetAudience>(this.targetAudiences);
      this.datasource.sort = this.sort;
      this.datasource.sortingDataAccessor = (data, sortHeaderId) => {
        return data.name.toLocaleLowerCase();
      };
    },
      error => console.error(error));
  }

  onListDrop(event: CdkDragDrop<TargetAudience[]>) {
    // Swap the elements around
    const prevIndex = this.targetAudiences.findIndex((d) => d === event.item.data);
    moveItemInArray(this.targetAudiences, prevIndex, event.currentIndex);
    this.datasource = new MatTableDataSource<TargetAudience>(this.targetAudiences);

    // Update the display order
    this.targetAudiences.map(x => {
      x.displayOrder = this.targetAudiences.findIndex(question => question.id === x.id) + 1;
      return x
    });

    // Save changes to the server
    this.http.post<any>(`api/targetaudience/order`, this.targetAudiences.map(x => {
      return { DisplayOrder: x.displayOrder, Id: x.id }
    })).subscribe(result => {
      console.log("POST is successful ", result);
    },
      error => console.error(error));
  }

  public onCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      targetAudience: {} as TargetAudience,
    };

    const dialogRef = this.dialog.open(TargetAudienceDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          const url = this.baseUrl + "api/targetaudience";
          this.http.post<TargetAudience>(url, data.settings).subscribe(result => {
            this.targetAudiences.push(result);
            this.datasource = new MatTableDataSource<TargetAudience>(this.targetAudiences);
          },
            error => console.error(error));
        }
      });
  }

  public editTargetAudience(targetAudience) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      targetAudience: targetAudience,
    };

    const dialogRef = this.dialog.open(TargetAudienceDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          const url = this.baseUrl + "api/targetaudience";
          this.http.put<TargetAudience>(url, data.settings).subscribe(() => {
            const newTargetAudiences = this.targetAudiences;
            const targetAudienceIndexToEdit = this.targetAudiences.findIndex(data => data.id === targetAudience.id);
            this.targetAudiences[targetAudienceIndexToEdit] = data.settings;
            this.targetAudiences = newTargetAudiences;
            this.datasource = new MatTableDataSource<TargetAudience>(this.targetAudiences);
          },
            error => console.error(error));
        }
      });
  }

  public deleteTargetAudience(targetAudience) {
    let message = "Are you sure you want to delete '";
    message = message + targetAudience.name;
    message = message + "'?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Call the server to delete the user
        const url = this.baseUrl + "api/targetaudience/" + targetAudience.id;
        this.http.delete(url)
          .subscribe(
            data => {
              console.log("DELETE Request is successful ", data);

              // Update the grid
              const newUsers = this.targetAudiences.filter(data => data.id !== targetAudience.id);
              this.targetAudiences = newUsers;
              this.datasource = new MatTableDataSource<TargetAudience>(this.targetAudiences);
            },
            error => {
              console.log("Error", error);
            }
          );
      }
    });
  }
}
