import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ValueFlag } from '../../interfaces/valueFlag';

@Component({
  selector: 'value-flag-details-dialog',
  templateUrl: './valueflag.details.dialog.component.html',
  styleUrls: ['./valueflag.details.dialog.component.css']
})

export class ValueFlagDetailsDialogComponent {
  flag: ValueFlag;
  answerSelectionId: number;
  form: UntypedFormGroup;
  title = "Add";
  redflagtype = 'none';

  private static FLOAT_PATTERN = '^-?[0-9]\\d*(\\.\\d*)?$';
  private static INTEGER_PATTERN = '^-?[0-9]\\d*';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ValueFlagDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog) {

    this.flag = data.flag;
    this.answerSelectionId = data.answerSelectionId;
    if (this.flag.id !== 0) {
      this.title = "Edit";
    }

    switch (this.answerSelectionId) {
      case 3:
        this.redflagtype = 'Integer';
        break;
      case 4:
        this.redflagtype = 'Float';
        break;
      default:
        this.redflagtype = 'none';
        break;
    }

    if (this.redflagtype === "Float" || this.redflagtype === "Integer") {
      this.form = this.formBuilder.group({
        minOrMaxValue: ['', Validators.required],
        minimumOrMaximum: 'Minimum',
        valueFlagText: [''],
        valueFlagType: 1,
      });
    }
    else {
      this.form = this.formBuilder.group({
        minOrMaxValue: '',
        minimumOrMaximum: '',
        valueFlagText: [''],
        valueFlagType: 1,
      });
    }

    if (data && data.flag && data.flag.id !== 0) {
      let minOrMaxValue: number;
      let minimumOrMaximum = "Maximum";
      if (data.flag.numericMinimumValue !== null) {
        minOrMaxValue = data.flag.numericMinimumValue;
        minimumOrMaximum = "Minimum";
      }
      if (data.flag.numericMaximumValue !== null) {
        minOrMaxValue = data.flag.numericMaximumValue;
        minimumOrMaximum = "Maximum";
      }

      this.form.setValue({
        minimumOrMaximum: minimumOrMaximum,
        minOrMaxValue: minOrMaxValue || '',
        valueFlagText: data.flag.valueFlagText || '',
        valueFlagType: data.flag.valueFlagType || 1,
      });
    }
  }

  setMinMaxValidators() {
    if (this.redflagtype === 'Integer') {
      this.form.controls['minOrMaxValue'].setValidators([Validators.required, Validators.pattern(ValueFlagDetailsDialogComponent.INTEGER_PATTERN)]);
    }
    else if (this.redflagtype === 'Float') {
      this.form.controls['minOrMaxValue'].setValidators([Validators.required, Validators.pattern(ValueFlagDetailsDialogComponent.FLOAT_PATTERN)]);
    }
    else {
      this.form.controls['minOrMaxValue'].setValidators(null);
    }

    this.form.updateValueAndValidity();
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && !e.valid;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };

    const dialogRef = this.dialog.open(ValueFlagDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let numericMaximumValue: number = null;
    let numericMinimumValue: number = null;

    if (this.flag.id === 0) {
      this.flag.id = Math.floor(Math.random() * 10000) * -1;
    }

    if (this.form.value.valueFlagType === 0) {
      this.form.value.valueFlagText = '';
    }

    if (this.redflagtype === "Integer" || this.redflagtype === "Float") {
      if (this.form.value.minimumOrMaximum === "Maximum") {
        numericMaximumValue = this.form.value.minOrMaxValue;
      }
      else {
        numericMinimumValue = this.form.value.minOrMaxValue;
      }
    }

    const returnFlag = {
      valueFlagText: this.form.value.valueFlagText,
      valueFlagType: parseInt(this.form.value.valueFlagType),
      numericMaximumValue: numericMaximumValue,
      numericMinimumValue: numericMinimumValue,
      id: this.flag.id,
      questionId: this.flag.questionId,
    };

    this.dialogRef.close({
      flag: returnFlag,
    });
  }
}
