import { Component, Inject, OnInit } from "@angular/core";
import { DynamicContent } from '../../interfaces/dynamicContent';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "paasp-certification",
  templateUrl: "./paasp.certification.component.html"
})

export class PaaspCertificationComponent {
  title = "PAASP Certification";
  dynamicContent: DynamicContent;
  safeDynamicContent: SafeHtml;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject('BASE_URL') private baseUrl: string
  ) {
  }

  ngOnInit() {
    this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/PAASP%20Certification").subscribe(result => {
      this.dynamicContent = result;
      this.safeDynamicContent = this.sanitizer.bypassSecurityTrustHtml(this.dynamicContent.content);
    }, error => {
      console.error(error)
      this.dynamicContent = {} as DynamicContent;
      this.dynamicContent.content = "An error occurred attempting to load content for this page.";
    });
  }
}
