import { Guid } from "guid-typescript";
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { CustomValidators } from '../custom-validators';
import { HttpClient } from "@angular/common/http";
import { NewUser } from '../../interfaces/newuser';
import { Role } from '../../interfaces/role';
import { User } from '../../interfaces/user';
import { UserType } from '../../interfaces/usertype';
import { CountryCode } from '../../interfaces/countryCode';
import { Organization } from '../../interfaces/organization';
import { TargetAudience } from "../../interfaces/targetaudience";
import { DynamicContent } from '../../interfaces/dynamicContent';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Observable } from "rxjs";
import { map, startWith } from 'rxjs/operators';
import { KeyValuePair } from '../../interfaces/keyValuePair';

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  breakpoint = 2;
  registrationComplete = false;
  editComplete  = false;
  emailChanged = false;
  emailDuplicate = false;
  userTypes: UserType[] = [];
  targetAudiences: TargetAudience[] = [];
  countryCodes: CountryCode[] = [];
  organizations: Organization[] = [];
  roles: Role[] = [];
  buttonText = "Register";
  filteredOrganizations: Observable<Organization[]>;
  mode = 'register';
  user: User = null;
  safeDynamicContent: SafeHtml;
  requiredFields: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject('BASE_URL') private baseUrl: string) {

    const id: string = this.activatedRoute.snapshot.paramMap.get("id");

    if (this.authenticationService.isAdmin()) {
      this.mode = "edit";
    }
    else {
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id !== id) {
        this.router.navigate(['/']);
      }
      else if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id === id) {
        this.mode = "edit";
      }
    }
  }

  ngOnInit() {
    if (this.mode === "edit") {
      const id: string = this.activatedRoute.snapshot.paramMap.get("id");
      const url = this.baseUrl + "api/user/" + id;
      this.http.get<User>(url).subscribe(result => {
        this.user = result;

        // update the form with the user data
        this.updateForm();
      }, error => console.error(error));
    }

    this.breakpoint = (window.innerWidth <= 400) ? 1 : 2;
    this.loginForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      position: [''],
      department: [''],
      organization: [''],
      role: [''],
      userType: [0],
      targetAudience: [0],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])],
      confirmPassword: ['', Validators.compose([Validators.required])],
      telephone: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: ['840'],
      piiPermission: [{ value: false, disabled: this.hasAlreadyAccepted() }, Validators.compose([Validators.requiredTrue])],
    },
    {
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
    });

    // fetch the user types from the server
    this.http.get<UserType[]>(this.baseUrl + "api/userType").subscribe(result => {
      this.userTypes = result;
    },
      error => console.error(error));

    // fetch the target audiences from the server
    this.http.get<TargetAudience[]>(this.baseUrl + "api/targetAudience").subscribe(result => {
      this.targetAudiences = result;
    },
      error => console.error(error));

    // fetch the user types from the server
    this.http.get<CountryCode[]>(this.baseUrl + "api/countryCode").subscribe(result => {
      this.countryCodes = result;
    },
      error => console.error(error));

    // fetch roles from the server
    this.http.get<Role[]>(this.baseUrl + "api/role/all").subscribe(result => {
      this.roles = result;
    },
      error => console.error(error));

    // fetch organizations from the server
    this.http.get<Organization[]>(this.baseUrl + "api/organization").subscribe(result => {
      this.organizations = result;
    },
      error => console.error(error));

    this.filteredOrganizations = this.loginForm.controls["organization"].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

    // get return URL from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/Register%20Page").subscribe(result => {
      this.safeDynamicContent = this.sanitizer.bypassSecurityTrustHtml(result.content);
    }, error => {
      console.error(error)
      this.safeDynamicContent = this.sanitizer.bypassSecurityTrustHtml("An error occurred attempting to load content for this page.");
    });

    this.http.get<KeyValuePair>(this.baseUrl + "api/keyvaluepair/name/registrationvalidation").subscribe(result => {
      this.requiredFields = result.value;
      let requiredFields = result.value.split(",");
      requiredFields.forEach(key => {
        if (key == 'userType' || key == 'targetAudience') {
          this.f[key].addValidators(Validators.compose([Validators.min(1), Validators.required]));
        }
        else {
          this.f[key].addValidators(Validators.compose([Validators.required]));
        }
        this.f[key].updateValueAndValidity();
      });
    },
      error => console.error(error));
  }

  private _filter(value: string): Organization[] {
    const filterValue = value.toLowerCase();

    return this.organizations.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  updateForm() {
    const roleValidators: ValidatorFn[] = [];
    if (this.authenticationService.isAdmin()) {
      roleValidators.push(Validators.required);
    }

    this.buttonText = "Update";
    this.loginForm = this.formBuilder.group({
      firstName: [this.user.firstName || '', Validators.required],
      lastName: [this.user.lastName || '', Validators.required],
      position: [this.user.position || ''],
      department: [this.user.department || ''],
      organization: [this.user.organization.name || '', Validators.required],
      role: [this.user.securityRole.id, roleValidators],
      userType: [this.user.userType.id || 0],
      targetAudience: [this.user.targetAudience.id || 0],
      email: [this.user.email || '', [Validators.required, Validators.email]],
      telephone: [this.user.phoneNumber || ''],
      addressLine1: [this.user.address1 || ''],
      addressLine2: [this.user.address2 || ''],
      city: [this.user.city || ''],
      state: [this.user.state || ''],
      zip: [this.user.zip || ''],
      country: [this.user.countryIsoNumericCode || ''],
      piiPermission: [this.user.piiPermission || false, Validators.compose([Validators.requiredTrue])]
    });
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 2;
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  hasAlreadyAccepted() {
    return this.user && this.user.piiPermission;
  }

  isRequired(field) {
    return this.requiredFields.includes(field);
  }

  onSubmit() {
    this.submitted = true;
    this.emailDuplicate = false;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    let id: string = Guid.EMPTY;
    if (this.mode === 'edit') {
      id = this.user.id;
    }

    const registrationData: NewUser = {
      id: id,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      position: this.f.position.value,
      department: this.f.department.value,
      userName: this.f.email.value,
      email: this.f.email.value,
      phoneNumber: this.f.telephone.value,
      organization: {
        id: Guid.EMPTY,
        name: this.f.organization.value,
        description: null,
      },
      securityRole: {
        id: this.f.role.value,
        name: '',
        priority: -1
      } as Role,
      address1: this.f.addressLine1.value,
      address2: this.f.addressLine2.value,
      city: this.f.city.value,
      state: this.f.state.value,
      zip: this.f.zip.value,
      countryIsoNumericCode: this.f.country.value,
      password: null,
      userType: {
        id: this.f.userType.value,
        name: '',
        description: '',
        displayOrder: 0,
      },
      targetAudience: {
        id: this.f.targetAudience.value,
        name: '',
        description: '',
        displayOrder: 0,
      },
      piiPermission: this.f.piiPermission.value,
    }

    this.loading = true;

    if (this.mode === 'edit') {
      const url = this.baseUrl + "api/user";
      this.http.put<NewUser>(url, registrationData).subscribe(result => {
        console.log("PUT Request is successful ", result);
        /*
        if (result.email !== this.user.email) {
          this.emailChanged = true;
          this.authenticationService.logout();
        }
        else {
        */
          this.editComplete = true;
        // }
        this.loading = false;
      },
        error => {
          console.error(error)
          this.loading = false;
        });
    }
    else if (this.mode === 'register') {
      registrationData.password = this.f.password.value;
      registrationData.securityRole.id = '6974684C-DEDD-48CF-B1C0-53078E2A25E0';

      const url = this.baseUrl + "api/user";
      this.http.post<NewUser>(url, registrationData).subscribe(result => {
        console.log("POST Request is successful ", result);
        this.registrationComplete = true;
        this.loading = false;
        window.scrollTo(0, 0);
      },
        error => {
          console.error(error);
          this.emailDuplicate = true;
          this.loading = false;
        });
    }
  }
}
