import { ChangeDetectorRef, Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { Survey } from '../../interfaces/survey';
import { Router } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Module } from '../../interfaces/module';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "survey-edit",
  templateUrl: './survey-edit.component.html',
  styleUrls: ['./survey-edit.component.less']
})

export class SurveyEditComponent implements OnInit {
  title: string;
  @Input() survey: Survey;
  @Output() surveyChange = new EventEmitter<Survey>();
  @Output() editComplete: EventEmitter<number> = new EventEmitter();
  @Output() reorderComplete: EventEmitter<number> = new EventEmitter();
  surveyLoaded = false;
  form: UntypedFormGroup;
  modules: Module[];
  modulesloaded = false;
  modulesDataSource: MatTableDataSource<Module> = new MatTableDataSource<Module>([]);
  displayedColumns: string[] = ['actions', 'module', 'description'];
  faBars = faBars;

  // this will be TRUE when editing an existing survey, 
  //   FALSE when creating a new one.
  editMode: boolean;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    // initialize the form
    this.createForm();

    this.http.get<Module[]>(this.baseUrl + "api/module/survey/" + this.survey.id).subscribe(result => {
      this.modules = result;
      this.modulesloaded = this.modules && (this.modules.length > 0);
      this.modulesDataSource = new MatTableDataSource<Module>(this.modules);
      this.changeDetectorRef.detectChanges();
    },
      error => console.error(error));

    if (this.survey) {
      this.editMode = true;

      // fetch the survey from the server
      this.title = "Edit - " + this.survey.name;
      this.surveyLoaded = true;

      // update the form with the survey value
      this.updateForm();
    }
    else {
      this.survey = {} as Survey;
      this.editMode = false;
      this.title = "Create a new Assessment";
    }
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: '',
      isAlwaysAvailable: false,
    });
  }

  updateForm() {
    this.form.setValue({
      name: this.survey.name,
      description: this.survey.description || '',
      isAlwaysAvailable: this.survey.isAlwaysAvailable || false,
    });
  }

  onSubmit() {
    // build a temporary survey object from form values
    const tempSurvey = {} as Survey;
    tempSurvey.name = this.form.value.name;
    tempSurvey.description = this.form.value.description;
    tempSurvey.isAlwaysAvailable = this.form.value.isAlwaysAvailable;

    const url = this.baseUrl + "api/survey";

    if (this.editMode) {
      tempSurvey.id = this.survey.id;

      this.http
        .post<Survey>(url, tempSurvey)
        .subscribe(res => {
          this.survey = res;
          console.log("assessment " + this.survey.id + " has been updated.");
          this.surveyChange.emit(this.survey);
          this.editComplete.emit(1);
        }, error => console.log(error));
    }
    else {
      this.http
        .put<Survey>(url, tempSurvey)
        .subscribe(res => {
          console.log("assessment " + res.id + " has been created.");
          this.router.navigate(['/home']);
        }, error => console.log(error));
    }
  }

  onBack() {
    this.editComplete.emit(0);
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onListDrop(event: CdkDragDrop<Module[]>) {
    // Swap the elements around
    const prevIndex = this.modules.findIndex((d) => d === event.item.data);
    moveItemInArray(this.modules, prevIndex, event.currentIndex);
    this.modulesDataSource = new MatTableDataSource<Module>(this.modules);

    // Update the display order
    this.modules.map(x => {
      x.displayOrder = this.modules.findIndex(question => question.id === x.id) + 1;
      return x
    });

    // Save changes to the server
    this.http.post<any>(`api/module/order`, this.modules.map(x => {
      return { DisplayOrder: x.displayOrder, Id: x.id }
    })).subscribe(result => {
      console.log("POST is successful ", result);
      this.reorderComplete.emit(1);
    },
      error => console.error(error));
  }
}
