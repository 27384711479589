import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { HttpClient, HttpEventType } from "@angular/common/http";
import { Survey } from '../../interfaces/survey';

@Component({
  selector: 'upload-dialog',
  templateUrl: 'upload-dialog.component.html',
})
export class UploadDialogComponent implements OnInit {
  form: UntypedFormGroup;
  surveys: Survey[];
  survey: UntypedFormControl;
  file: UntypedFormControl;
  timer = null;
  selectedFile: File = null;
  uploadInProgress = false;
  progressbarValue = 0;
  fileProgress = 0;
  loading = false;
  hasError = false;

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {
    this.survey = new UntypedFormControl(-1);
    this.file = new UntypedFormControl();

    this.form = this.fb.group({
      file: ['', Validators.required],
      survey: this.survey,
    });  }

  ngOnInit() {
    this.loading = true;
    this.http.get<Survey[]>(this.baseUrl + "api/answerset/user").subscribe(result => {
      const noSurveyOption = {} as Survey;
      noSurveyOption.name = 'No Assessment';
      noSurveyOption.id = -1;
      noSurveyOption.surveyVersionId = -1;
      noSurveyOption.version = -1;
      noSurveyOption.displayOrder = -1;
      noSurveyOption.percentComplete = 0;

      this.surveys = result;
      this.surveys.push(noSurveyOption);
      this.loading = false;
    });
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.progressbarValue = this.fileProgress;
    }, 1000);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  onFileChange(event) {
    this.selectedFile = event.target.files[0] as File;
  }

  onSubmit() {
    this.loading = true;
    this.hasError = false;

    const formData = new FormData();
    formData.append("id", this.form.get('survey').value);
    formData.append('file', this.selectedFile);
    formData.append('name', '');
    formData.append('date', '');
    formData.append('survey', '');

    const url = this.baseUrl + "api/upload";
    this.uploadFile(url, formData);
  }

  uploadFile(url, formData) {
    this.fileProgress = 0;
    this.uploadInProgress = true;
    this.progressbarValue = 0;
    this.startTimer();

    this.http.post(url, formData, { reportProgress: true, observe: 'events' })
      .subscribe(
        resp => {
          if (resp.type === HttpEventType.Response) {
            this.loading = false;
            this.stopTimer();
            console.log("File answer has been uploaded.");
            this.uploadInProgress = false;
            this.dialogRef.close(resp.body);
          }
          if (resp.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * resp.loaded / resp.total);
            if (percentDone > this.fileProgress) {
              this.fileProgress = percentDone;
            }
            console.log('Progress ' + percentDone + '%');
          }
        },
        error => {
          this.uploadInProgress = false;
          this.hasError = true;
          console.log(error)
          this.stopTimer();
      });
  }
}
