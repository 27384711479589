import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { Organization } from '../../interfaces/organization';

@Component({
  selector: 'organization-merge-dialog',
  templateUrl: './organization-merge-dialog.component.html',
  styleUrls: ['./organization-merge-dialog.component.css']
})

export class OrganizationMergeDialogComponent implements OnInit {
  organization: Organization;
  organizations: Organization[];
  form: UntypedFormGroup;
  isAdmin: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<OrganizationMergeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    this.organization = data.organization;
    this.organizations = data.organizations;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      targetId: new UntypedFormControl('', Validators.required),
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };

    const dialogRef = this.dialog.open(OrganizationMergeDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }

  close() {
    this.dialogRef.close();
  }

  getControls() {
    return (this.form.controls.flags as UntypedFormArray).controls;
  }

  save() {
    const returnSettings = {
      targetId: this.form.value.targetId,
    };

    this.dialogRef.close({
      settings: returnSettings
    });
  }
}
