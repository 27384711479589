import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RoleDetailsDialogComponent } from './role.details.dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuthenticationService } from "../../services/authentication.service";
import { FileService } from "../../services/file.service";
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Role } from '../../interfaces/role';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})

export class RoleListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'isadmin', 'actions'];
  roles: Role[];
  datasource: MatTableDataSource<Role>;
  faPlus = faPlus;
  faEdit = faEdit;

  constructor(private dialog: MatDialog,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private fileService: FileService,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const url = this.baseUrl + "api/role/All";
    this.http.get<Role[]>(url).subscribe(result => {
      this.roles = result;
      this.datasource = new MatTableDataSource<Role>(this.roles);
    },
      error => console.error(error));
  }

  isAdmin(role: Role) {
    return role.name === 'SysAdmin';
  }

  public onCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      role: {} as Role,
    };

    const dialogRef = this.dialog.open(RoleDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          if (data.settings.admin) {
            data.settings.priority = 1
          } else {
            data.settings.priority = 100;
          }
          const url = this.baseUrl + "api/role";
          this.http.post(url, data.settings).subscribe(result => {
            this.roles.push(result as Role);
            this.datasource = new MatTableDataSource<Role>(this.roles);
          },
            error => console.error(error));
        }
      });
  }

  public editRole(role) {
    const dialogConfig = new MatDialogConfig();
    const roleData = this.roles.find(data => data.id === role.id);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      role: roleData,
    };

    const dialogRef = this.dialog.open(RoleDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          if (data.settings.admin) {
            data.settings.priority = 1
          } else {
            data.settings.priority = 100;
          }
          const url = this.baseUrl + "api/role";
          this.http.put(url, data.settings).subscribe(() => {
            const newRoleData = this.roles;
            const roleIndexToEdit = this.roles.findIndex(data => data.id === role.id);
            this.roles[roleIndexToEdit] = data.settings;
            this.roles = newRoleData;
            this.datasource = new MatTableDataSource<Role>(this.roles);
          },
            error => console.error(error));
        }
      });
  }

  public deleteRole(role) {
    let message = "Are you sure want to delete role ";
    if (role.name !== null && role.name !== "") {
      message = message + role.name;
    }
    message = message + "?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Call the server to delete the role
        const url = this.baseUrl + "api/role/" + role.id;
        this.http.delete(url)
          .subscribe(
            data => {
              console.log("DELETE Request is successful ", data);

              // Update the grid
              const newRoles = this.roles.filter(data => data.id !== role.id);
              this.roles = newRoles;
              this.datasource = new MatTableDataSource<Role>(this.roles);
            },
            error => {
              console.log("Error", error);
            }
          );
      }
    });
  }
}
