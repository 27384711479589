import { Component, Inject, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})

export class ConfirmEmailComponent implements OnInit {
  message: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    const id: string = this.activatedRoute.snapshot.paramMap.get("id");
    if (id) {
      const url = this.baseUrl + "api/user/confirm/" + id;

      this.http.get(url).subscribe(() => {
        this.router.navigate(['../home']);
      }, error => {
        this.message = "Unable to confirm your email address.";
        console.error(error);
      });
    }
  }
}
