import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MultipleChoiceAnswer } from "../../interfaces/multipleChoiceAnswer";
import { DecimalValidationDirective } from '../directives/decimalValidation.directive';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'multiple-choice-answer-details-dialog',
  templateUrl: './multplechoiceanswer.details.dialog.component.html',
  styleUrls: ['./multplechoiceanswer.details.dialog.component.css']
})

export class MultipleChoiceAnswerDetailsDialogComponent {
  answer: MultipleChoiceAnswer;
  form: UntypedFormGroup;
  title = "Add";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<MultipleChoiceAnswerDetailsDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog) {

    this.answer = data.answer;
    if (this.answer.answerText && this.answer.answerText.length >= 0) {
      this.title = "Edit";
    }

    this.form = this.formBuilder.group({
      answerText: ['', Validators.required],
      valueFlagText: '',
      valueFlagType: 0,
      weight: [null, Validators.min(0)]
    });

    if (data && data.answer) {
      this.form.setValue({
        answerText: data.answer.answerText || '',
        valueFlagText: data.answer.valueFlagText || '',
        valueFlagType: data.answer.valueFlagType || 0,
        weight: data.answer.weight,
      });
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };

    const dialogRef = this.dialog.open(MultipleChoiceAnswerDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.value.valueFlagType === 0) {
      this.form.value.valueFlagText = '';
    }

    const returnAnswer: MultipleChoiceAnswer = {
      answerText: this.form.value.answerText,
      valueFlagText: this.form.value.valueFlagText,
      valueFlagType: parseInt(this.form.value.valueFlagType),
      safeAnswerText: this.cleanHtml(this.form.value.answerText),
      id: this.answer.id,
      questionId: this.answer.questionId,
      weight: this.form.value.weight,
      displayOrder: null,
    };

    this.dialogRef.close({
      answer: returnAnswer
    });
  }

  private cleanHtml(html: string) {
    if (html) {
      if (html.startsWith("<p>")) {
        const doc = html.trim().slice(3, html.trim().length - 4);
        return this.sanitizer.bypassSecurityTrustHtml(doc);
      }
      else {
        return html;
      }
    }
    else {
      return '';
    }
  }
}
