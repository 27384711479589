import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  transform(value: Date | string, format = 'medium'): string {
    if (value) {
      let language = this.translateService.currentLang || this.translateService.getBrowserLang() || 'en';
      if (!['en', 'de', 'hy', 'fr', 'it', 'es', 'ja'].includes(language)) {
        language = 'en';
      }
      const datePipe = new DatePipe(language);
      return datePipe.transform(value + 'Z', format);
    }
    else {
      return '';
    }
  }
}
