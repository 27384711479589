import { Component, Inject, Input, ChangeDetectorRef, OnInit, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { faChevronDown, faChevronUp, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Question } from '../../interfaces/question';
import { Survey } from '../../interfaces/survey';
import { Category } from '../../interfaces/category';
import { User } from '../../interfaces/user';
import { SurveyStatusResult } from '../../interfaces/surveyStatusResult';
import { SurveyService } from '../../services/survey.service';

@Component({
  selector: "question-list",
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.less']
})

export class QuestionListComponent implements OnInit {
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faPlus = faPlus;
  faCheck = faCheck;
  @Input() category: Category;
  @Input() survey: Survey;
  @Input() isDragDropMode: boolean;
  @Output() nextCategoryEvent = new EventEmitter<Category>();
  @Output() previousCategoryEvent = new EventEmitter<Category>();
  @Output() createStart: EventEmitter<number> = new EventEmitter();
  @Output() questionEditEvent: EventEmitter<Question> = new EventEmitter();
  @Output() questionEditCompleteEvent: EventEmitter<number> = new EventEmitter();
  title: string;
  questions: Question[];
  selectedQuestion: Question;
  user: User;
  canEditSurveys = false;
  questionsloaded = false;
  questionsDataSource: MatTableDataSource<Question> = new MatTableDataSource<Question>([]);
  displayedColumns: string[] = ['actions', 'question', 'answerText'];
  surveyComplete: boolean;

  constructor(private http: HttpClient,
    private authService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string,
    private changeDetectorRef: ChangeDetectorRef,
    private surveyService: SurveyService) {
    this.canEditSurveys = this.authService.isAdmin() && this.authService.hasFlag('a20f0ccb-e655-410c-9aaa-e272532c4d65');
    this.surveyService.completionUpdated$.subscribe(result => this.onCompletionChanged(result));
  }

  ngOnInit() {
    const url = this.baseUrl + "api/question/category/" + this.category.id;
    this.canEditSurveys = this.canEditSurveys && !this.survey.publishedDate;

    this.http.get<Question[]>(url).subscribe(result => {
      this.questions = result;
      this.questionsDataSource = new MatTableDataSource<Question>(this.questions);
      this.changeDetectorRef.detectChanges();
      this.questionsloaded = this.questions && (this.questions.length > 0);
      if (this.survey) {
        this.surveyService.updateCompletion(this.survey.surveyVersionId);
      }
    },
      error => console.error(error));
  }

  ngOnChanges(changes) {
    this.category = changes.category.currentValue;
    const url = this.baseUrl + "api/question/category/" + this.category.id;

    this.http.get<Question[]>(url).subscribe(result => {
      this.questions = result;
      this.questionsDataSource = new MatTableDataSource<Question>(this.questions);
      this.changeDetectorRef.detectChanges();
      this.questionsloaded = this.questions && (this.questions.length > 0);
    },
      error => console.error(error));
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  onCreate() {
    console.log("Add Question has been selected.");
    this.createStart.emit(1);
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  onListDrop(event: CdkDragDrop<Question[]>) {
    // Swap the elements around
    const prevIndex = this.questions.findIndex((d) => d === event.item.data);
    moveItemInArray(this.questions, prevIndex, event.currentIndex);
    this.questionsDataSource = new MatTableDataSource<Question>(this.questions);

    // Update the display order
    this.questions.map(x => {
      x.displayOrder = this.questions.findIndex(question => question.id === x.id) + 1;
      return x
    });

    // Save changes to the server
    this.http.post<any>(`api/question/order`, this.questions.map(x =>
    {
      return { DisplayOrder: x.displayOrder, Id: x.id }
    })).subscribe(result => {
      this.questions = this.questions.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
      console.log("POST is successful ", result);
    },
      error => console.error(error));
  }

  onQuestionChanged(event: Question) {
    const questions = this.questions.filter((q) => q.id === event.id);
    if (questions && questions.length === 1) {
      questions[0].answer = Object.assign({}, event.answer);
    }
  }

  onQuestionEdit(event: number) {
    const question = this.questions.filter(x => x.id === event)[0];
    this.questionEditEvent.emit(question);
  }

  onQuestionDeleted(event: number) {
    this.questions = this.questions.filter(q => q.id !== event);
  }

  onQuestionEditComplete(event: number) {
    this.questionEditCompleteEvent.emit(event);
  }

  showQuestion(question: Question) {
    if (question.parentQuestionId === null) {
      // This question has no parent.
      return true;
    }

    if (this.authService.isAdmin() && this.survey?.publishedDate) {
      return true;
    }

    const result = this.questions.filter(q => q.id === question.parentQuestionId);
    if (result.length > 0) {
      const parentQuestion = result[0];
      if (parentQuestion.answer === null) {
        return false;
      }

      if (question.parentFollowUpAnswer === null ||
        question.parentFollowUpAnswer.trim() === '') {
        return false;
      }

      if (parentQuestion.answerType.toLowerCase().startsWith('multichoice')) {
        let multipleChoiceAnswerString: string;
        if (parentQuestion.answerType.toLowerCase().startsWith('multichoicesingle') && parentQuestion.answer.multipleChoiceSingleAnswer) {
          multipleChoiceAnswerString = parentQuestion.answer.multipleChoiceSingleAnswer.toString();
        }
        else if (parentQuestion.answerType.toLowerCase().startsWith('multichoicemulti') && parentQuestion.answer.multipleChoiceMultipleAnswers) {
          multipleChoiceAnswerString = parentQuestion.answer.multipleChoiceMultipleAnswers;
        }
        else {
          return false;
        }

        let multipleChoiceAnswers: number[];
        if (!multipleChoiceAnswerString.startsWith('[')) {
          multipleChoiceAnswerString = '[' + multipleChoiceAnswerString + ']';
        }

        multipleChoiceAnswers = JSON.parse(multipleChoiceAnswerString);

        let parentFollowUpAnswer = question.parentFollowUpAnswer;
        if (!parentFollowUpAnswer.startsWith('[')) {
          parentFollowUpAnswer = '[' + parentFollowUpAnswer + ']';
        }

        const followUpAnswers = JSON.parse(parentFollowUpAnswer);
        const filteredArray = followUpAnswers.filter(id => multipleChoiceAnswers.includes(id));
        if (filteredArray.length > 0) {
          return true;
        }
      }

      return false;
    }
  }

  onNextCategory() {
    console.log("Next category clicked.");
    this.nextCategoryEvent.emit(this.category);
  }

  onPreviousCategory() {
    console.log("Previous category clicked.");
    this.previousCategoryEvent.emit(this.category);
  }

  onCompletionChanged(completion: SurveyStatusResult) {
    this.surveyComplete = completion.surveyStatus?.percentComplete >= 100;
  }
}
