import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  isTimeout = false;
  showRememberMe = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
    const userName = localStorage.getItem('rememberMe');

    const functionalCookies = localStorage.getItem('functionalCookiesAccepted');

    if (functionalCookies !== null) {
      this.showRememberMe = true;
    }

    this.loginForm = this.formBuilder.group({
      username: [userName, Validators.required],
      password: ['', Validators.required],
      rememberMe: userName && userName.length > 0
    });

    // get return URL from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    this.isTimeout = this.router.url.includes('timeout');
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        () => {
          this.router.navigate([this.returnUrl]);
          const functionalCookies = localStorage.getItem('functionalCookiesAccepted');
          if (this.f.rememberMe.value && functionalCookies !== null) {
            localStorage.setItem('rememberMe', this.f.username.value);
          }
          else {
            localStorage.removeItem("rememberMe");
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
