import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurveyResult } from '../../interfaces/surveyResult';
import { LocalizedDatePipe } from "../../app/helpers/localized-date.pipe";

@Component({
  selector: 'score-summary-dialog',
  templateUrl: 'score-summary-dialog.component.html',
})
export class ScoreSummaryDialogComponent {
  public surveyResult: SurveyResult;
  public scores: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SurveyResult,
    private dialogRef: MatDialogRef<ScoreSummaryDialogComponent>) {
    this.surveyResult = data;
    if (data) {
      this.surveyResult = data;
      this.scores = JSON.parse(this.surveyResult.moduleAndCategoryScores);
    }
  }

  onClose(): void {
    this.dialogRef.close(true);
  }
}
