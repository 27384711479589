import { Component, OnInit, Inject, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { DomSanitizer } from '@angular/platform-browser'
import { Question } from '../../interfaces/question';
import { Survey } from '../../interfaces/survey';
import { SurveyService } from '../../services/survey.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: "question",
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.less']
})

export class QuestionComponent {
  @Input() question: Question;
  @Input() survey: Survey;
  @Input() isDragDropMode: boolean;
  @Output() editStart: EventEmitter<number> = new EventEmitter();
  @Output() deleteComplete: EventEmitter<number> = new EventEmitter();
  canEditSurveys = false;
  isAdminUser = false;
  isLoggedIn = false;
  editMode = false;
  faEdit = faEdit;
  faTrash = faTrash;
  surveyVersionId: number;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private surveyService: SurveyService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.canEditSurveys = this.authService.isAdmin() && this.authService.hasFlag('a20f0ccb-e655-410c-9aaa-e272532c4d65');
    this.isAdminUser = this.authService.isAdmin();
    this.isLoggedIn = this.authService.currentUserValue !== null;
    this.activatedRoute.params.subscribe(params => {
      this.surveyVersionId = +params['id'];
    });
  }

  ngOnInit() {
    this.question.safeText = this.sanitizer.bypassSecurityTrustHtml(this.question.text);
    if (this.question.multipleChoiceAnswers) {
      this.question.multipleChoiceAnswers.forEach(mca => {
        mca.safeAnswerText = this.sanitizer.bypassSecurityTrustHtml(mca.answerText);
      });
    }
  }

  onEdit() {
    this.editStart.emit(this.question.id);
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }
  
  onDelete() {
    if (confirm("Do you really want to delete this question?")) {
      const url = this.baseUrl + "api/question/" + this.question.id;
      this.http
        .delete(url)
        .subscribe(() => {
          console.log("Question " + this.question.id + " has been deleted.");
          this.deleteComplete.emit(this.question.id);
        }, error => console.log(error));
    }
  }

  onAnswerChanged(event: Question) {
    this.surveyService.updateCompletion(this.surveyVersionId);
  }
}
