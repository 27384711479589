import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { AuthenticationService } from "../../services/authentication.service";
import { UserType } from '../../interfaces/userType';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { UserTypeDetailsDialogComponent } from '../user-type/user-type-details-dialog.component';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-type-list',
  templateUrl: './user-type-list.component.html',
  styleUrls: ['./user-type-list.component.css']
})

export class UserTypeListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'actions'];
  userTypes: UserType[];
  datasource: MatTableDataSource<UserType>;
  isAdminUser = false;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const url = this.baseUrl + "api/usertype";
    this.http.get<UserType[]>(url).subscribe(result => {
      this.userTypes = result;
      this.datasource = new MatTableDataSource<UserType>(this.userTypes);
      this.datasource.sort = this.sort;
      this.datasource.sortingDataAccessor = (data, sortHeaderId) => {
        return data.name.toLocaleLowerCase();
      };
    },
      error => console.error(error));
  }

  onListDrop(event: CdkDragDrop<UserType[]>) {
    // Swap the elements around
    const prevIndex = this.userTypes.findIndex((d) => d === event.item.data);
    moveItemInArray(this.userTypes, prevIndex, event.currentIndex);
    this.datasource = new MatTableDataSource<UserType>(this.userTypes);

    // Update the display order
    this.userTypes.map(x => {
      x.displayOrder = this.userTypes.findIndex(question => question.id === x.id) + 1;
      return x
    });

    // Save changes to the server
    this.http.post<any>(`api/usertype/order`, this.userTypes.map(x => {
      return { DisplayOrder: x.displayOrder, Id: x.id }
    })).subscribe(result => {
      console.log("POST is successful ", result);
    },
      error => console.error(error));
  }

  public onCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      userType: {} as UserType,
    };

    const dialogRef = this.dialog.open(UserTypeDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          const url = this.baseUrl + "api/usertype";
          this.http.post<UserType>(url, data.settings).subscribe(result => {
            this.userTypes.push(result);
            this.datasource = new MatTableDataSource<UserType>(this.userTypes);
          },
            error => console.error(error));
        }
      });
  }

  public editUserType(userType) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      userType: userType,
    };

    const dialogRef = this.dialog.open(UserTypeDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          const url = this.baseUrl + "api/usertype";
          this.http.put<UserType>(url, data.settings).subscribe(() => {
            const newUserTypes = this.userTypes;
            const userTypeIndexToEdit = this.userTypes.findIndex(data => data.id === userType.id);
            this.userTypes[userTypeIndexToEdit] = data.settings;
            this.userTypes = newUserTypes;
            this.datasource = new MatTableDataSource<UserType>(this.userTypes);
          },
            error => console.error(error));
        }
      });
  }

  public deleteUserType(userType) {
    let message = "Are you sure you want to delete '";
    message = message + userType.name;
    message = message + "'?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Call the server to delete the user
        const url = this.baseUrl + "api/usertype/" + userType.id;
        this.http.delete(url)
          .subscribe(
            data => {
              console.log("DELETE Request is successful ", data);

              // Update the grid
              const newUsers = this.userTypes.filter(data => data.id !== userType.id);
              this.userTypes = newUsers;
              this.datasource = new MatTableDataSource<UserType>(this.userTypes);
            },
            error => {
              console.log("Error", error);
            }
          );
      }
    });
  }
}
