import { Component } from "@angular/core";
import { AuthenticationService } from "../../services/authentication.service";
import { Router } from '@angular/router';

@Component({
    selector: "admin",
    templateUrl: "./admin.component.html"
})

export class AdminComponent {
  title = "Administration";
  isAdminUser = false;
  canEditUsers = false;
  canViewSurveys = false;
  canEditDynamicContent = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {
    this.isAdminUser = this.authenticationService.isAdmin();
    this.canEditUsers = this.authenticationService.hasFlag("CCB7395C-10A1-49F4-B52D-1B13442DD7BA");
    this.canViewSurveys = this.authenticationService.hasFlag("A6195489-DEE5-4C57-8574-68D55D2AE708");
    this.canEditDynamicContent = this.authenticationService.hasFlag("205F0CA9-9AAA-E655-410C-A272532C4BED");

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }
  }
}
