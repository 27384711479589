import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cookie-consent-dialog',
  templateUrl: 'cookie-consent.component.html',
  styleUrls: ['cookie-consent.component.css']
})
export class CookieConsentComponent {
  formGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private router: Router,
    formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CookieConsentComponent>) {
    this.formGroup = formBuilder.group({
      necessaryCookies: true,
      functionalCookies: true,
    });
    this.formGroup.get('necessaryCookies').disable()
  }

  onAcceptClick(): void {
    const check = this.formGroup.controls['functionalCookies'].value;
    if (check) {
      this.dialogRef.close(2);
    }
    else {
      this.dialogRef.close(1);
    }
  }

  onCookiePolicy(): void {
    this.dialogRef.close(0);
    this.router.navigate(['/about/policy']);
  }
}
