import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuditLogEntry } from '../../interfaces/auditLogEntry';
import { User } from '../../interfaces/user';
import { LocalizedDatePipe } from "../../app/helpers/localized-date.pipe";

@Component({
  selector: 'user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css']
})

export class UserActivityComponent implements OnInit {

  displayedColumns: string[] = ['logDateTime', 'eventName', 'eventMessage'];
  auditLogEntries: AuditLogEntry[];
  user: User;
  datasource: MatTableDataSource<AuditLogEntry>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    const userId = this.activatedRoute.snapshot.paramMap.get("id");

    this.http.get<AuditLogEntry[]>(this.baseUrl + "api/user/" + userId + "/activity").subscribe(result => {
      this.auditLogEntries = result;
      this.datasource = new MatTableDataSource<AuditLogEntry>(this.auditLogEntries);
    },
      error => console.error(error));

    this.http.get<User>(this.baseUrl + "api/user/" + userId ).subscribe(result => {
      this.user = result;
    },
      error => console.error(error));
  }
}
