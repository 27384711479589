import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { CustomValidators } from '../custom-validators';
import { AuthenticationService } from '../../services/authentication.service';
import { PasswordChange } from '../../interfaces/passwordChange';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { NewUser } from '../../interfaces/newuser';
import { Router } from '@angular/router';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent {
  form: UntypedFormGroup;
  submitted: boolean;
  passwordChanged = false;
  error = '';

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {

    this.form = this.formBuilder.group({
      oldPassword: ['', Validators.compose([Validators.required]) ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])],
      confirmPassword: ['', Validators.compose([Validators.required])],
    },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const passwordData: PasswordChange = {
      id: this.authenticationService.currentUserValue.id,
      newPassword: this.f.password.value,
      oldPassword: this.form.controls.oldPassword.value,
    };

    const url = this.baseUrl + "api/user/password";
    this.http.put<PasswordChange>(url, passwordData).subscribe(result => {
      console.log("Change password is successful ", result);

      this.passwordChanged = true;
      this.resetForm();
    },
      error => {
        console.error(error)
        this.passwordChanged = false;
        this.resetForm();
      });
  }

  resetForm() {
    this.submitted = false;
    this.f.oldPassword.setValue(null);
    this.f.password.setValue(null);
    this.f.confirmPassword.setValue(null);
  }

  onDelete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete your account? This action cannot be undone.",
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const url = this.baseUrl + "api/user/" + this.authenticationService.currentUserValue.id;
        this.http.delete<NewUser>(url).subscribe(result => {
          console.log("Delete Request is successful ", result);
          this.authenticationService.logout();
          this.router.navigate(["survey"]);
        },
          error => {
            console.error(error);
            this.error = "An error occurred while attempting to delete your account."
          });
      }
      else {
        this.submitted = false;
      }
    });
  }
}
