import { Component, Inject, OnInit, ViewChildren, ElementRef, QueryList, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Question } from '../../interfaces/question';
import { Module } from '../../interfaces/module';
import { ReportRecord } from '../../interfaces/reportRecord';
import { User } from '../../interfaces/user';
import { ValueFlag } from '../../interfaces/valueFlag';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { DynamicContent } from '../../interfaces/dynamicContent';
import { ActivatedRoute } from "@angular/router";
import { LocalizedDatePipe } from "../../app/helpers/localized-date.pipe";
import { DecimalPipe } from '@angular/common';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { PageSize, PageOrientation, Margins } from 'pdfmake/interfaces';
import { Survey } from "../../interfaces/survey";
import { AnswerSet } from "../../interfaces/answerset";
const htmlToPdfmake = require("html-to-pdfmake");
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

interface SummaryCategory {
  id: number;
  name: string;
  description: string;
  displayOrder: number;
  reportRecords: ReportRecord[];
  hidden: boolean;
};

interface SummaryModule {
  id: number;
  name: string;
  description: string;
  displayOrder: number;
  categories: SummaryCategory[];
  hidden: boolean;
};

@Component({
  selector: "survey-summary",
  templateUrl: './survey-summary.component.html',
  styleUrls: ['./survey-summary.component.less']
})

export class SurveySummaryComponent implements OnInit {
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  title: string;
  selectedModule: Module;
  summaryQuestions: SummaryModule[];
  user: User;
  isAdminUser = false;
  questionsloaded = false;
  greenFlagCount = 0;
  yellowFlagCount = 0;
  redFlagCount = 0;
  surveyName = '';
  surveyCompletionDate: Date;
  showSurveySummary = false;
  showSurveyFlags = false;
  showGrpScore = false;
  isCreatingPdf = false;
  answerSetId: string;
  surveySummaryDynamicContentHeader: DynamicContent;
  surveySummaryDynamicContent: DynamicContent;
  surveySummaryDynamicContentFooter: DynamicContent;
  scheduleMeetingDynamicContent: DynamicContent;
  surveySummarySafeHtml: SafeHtml;
  surveySummaryHeaderSafeHtml: SafeHtml;
  surveySummaryFooterSafeHtml: SafeHtml;
  scheduleMeetingSafeHtml: SafeHtml
  moduleCount = 0;
  categoryCount = 0;
  score: number;

  @ViewChildren('question') reportQuestions: QueryList<ElementRef>
  @ViewChild('header') headerElement: ElementRef;
  @ViewChild('counts') countsElement: ElementRef;
  @ViewChild('footer') footerElement: ElementRef;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private sanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.isAdminUser = this.authService.isAdmin();
  }

  private cleanHtml(html: string) {
    if (html) {
      if (html.startsWith("<p>")) {
        const doc = html.trim().slice(3, html.trim().length - 4);
        return this.sanitizer.bypassSecurityTrustHtml(doc);
      }
      else {
        return html;
      }
    }
    else {
      return '';
    }
  }

  ngOnInit() {
    this.answerSetId = this.activatedRoute.snapshot.queryParamMap.get("id");
    const reportRecords = [] as ReportRecord[];

    var url = this.baseUrl + "api/user/";
    if (this.isAdminUser) {
      url = url + this.activatedRoute.snapshot.queryParamMap.get("u");;
      this.http.get<User>(url).subscribe(result => {
        this.user = result;
      });
    }
    else {
      this.user = this.authService.currentUserValue;
    }

    // get the list of all modules
    url = this.baseUrl + "api/question/last/";
    if (this.answerSetId) {
      url = url + this.answerSetId;
    }

    this.http.get<Question[]>(url).subscribe(result => {
      this.questionsloaded = result && (result.length > 0);

      // Calculation of counts of flag types
      result.forEach(question => {
        question.safeText = this.cleanHtml(question.text);
        if (question.multipleChoiceAnswers) {
          question.multipleChoiceAnswers.forEach(mca => {
            mca.safeAnswerText = this.cleanHtml(mca.answerText);
          });
        }
      });

      result.forEach(question => {
        if (question.answer === null) {
          return;
        }

        const reportRecord = {} as ReportRecord;
        reportRecord.questionText = question.text;
        reportRecord.safeQuestionText = question.safeText;
        reportRecord.valueFlagType = [];
        reportRecord.valueFlagText = [];
        reportRecord.answerType = question.answerType;

        reportRecord.categoryName = question.categoryName;
        reportRecord.moduleName = question.moduleName;

        if (question.answerType.startsWith('multichoicesingle') &&
          question.multipleChoiceAnswers &&
          question.multipleChoiceAnswers.length > 0) {
          reportRecord.multipleChoiceAnswers = question.multipleChoiceAnswers;

          const answer = question.multipleChoiceAnswers.filter(answer => {
            return answer.id === question.answer.multipleChoiceSingleAnswer;
          });

          reportRecord.multipleChoiceAnswers.forEach(mca => {
            mca.weight = 0;
            if (answer !== null && answer.length > 0 && answer[0].id == mca.id) {
              mca.weight = 1;
            }
          });

          reportRecord.answerText = question.answer.displayText;

          if (answer !== null && answer.length > 0) {
            this.incrementFlagCount(answer[0].valueFlagType);
            reportRecord.valueFlagType.push(answer[0].valueFlagType);
            reportRecord.valueFlagText.push(answer[0].valueFlagText);
          }
        }
        else if (question.answerType.indexOf('multichoicemulti') === 0 &&
          question.multipleChoiceAnswers &&
          question.multipleChoiceAnswers.length > 0) {
          reportRecord.answerText = question.answer.displayText;
          reportRecord.multipleChoiceAnswers = question.multipleChoiceAnswers;
          reportRecord.multipleChoiceAnswers.forEach(mca => {
            mca.safeAnswerText = this.cleanHtml(mca.answerText);
            mca.weight = 0;
          });

          const selectedAnswers = question.answer.multipleChoiceMultipleAnswers.split(',');
          for (let i = 0; i < question.multipleChoiceAnswers.length; i++) {
            if (selectedAnswers.filter(a => a === question.multipleChoiceAnswers[i].id.toString()).length > 0) {
              if (question.multipleChoiceAnswers[i].valueFlagType > 0) {
                this.incrementFlagCount(question.multipleChoiceAnswers[i].valueFlagType);
                reportRecord.valueFlagType.push(question.multipleChoiceAnswers[i].valueFlagType);
                reportRecord.valueFlagText.push(question.multipleChoiceAnswers[i].valueFlagText);
              }
            }
          }

          reportRecord.multipleChoiceAnswers.forEach(mca => {
            if (selectedAnswers.includes(mca.id.toString())) {
              mca.weight = 1;
            }
          });
        }
        else if (question.answerType.indexOf("file") === 0) {
          if (question.answer.isExplicitlyNoFileAnswer) {
            reportRecord.answerText = "You explicitly opted to not upload a file.";
            reportRecord.safeAnswerText = this.cleanHtml("You explicitly opted to not upload a file.")
            // reportRecord.valueFlagText.push("You explicitly opted to not upload a file.");
            // reportRecord.valueFlagType = 3;
            // this.incrementFlagCount(3);
          }
          else {
            reportRecord.answerText = question.answer.fileDescription;
            reportRecord.safeAnswerText = this.cleanHtml(question.answer.fileDescription)
          }
        }
        else {
          reportRecord.answerText = question.answer.displayText;
          reportRecord.safeAnswerText = this.cleanHtml(question.answer.displayText)
        }

        if (question.valueFlags) {
          question.valueFlags.forEach(valueFlag => {
            if (this.isBest(valueFlag, question.valueFlags)) {
              if (question.answer &&
                question.answer.floatAnswer &&
                valueFlag.numericMaximumValue &&
                question.answer.floatAnswer >= valueFlag.numericMaximumValue) {
                reportRecord.answerText = question.answer.displayText;
                reportRecord.safeAnswerText = this.cleanHtml(question.answer.displayText)
                reportRecord.valueFlagType.push(valueFlag.valueFlagType);
                reportRecord.valueFlagText.push(valueFlag.valueFlagText);
                this.incrementFlagCount(valueFlag.valueFlagType);
              }
              else if (question.answer &&
                question.answer.floatAnswer &&
                valueFlag.numericMaximumValue &&
                question.answer.floatAnswer <= valueFlag.numericMinimumValue) {
                reportRecord.answerText = question.answer.displayText;
                reportRecord.safeAnswerText = this.cleanHtml(question.answer.displayText)
                reportRecord.valueFlagType.push(valueFlag.valueFlagType);
                reportRecord.valueFlagText.push(valueFlag.valueFlagText);
                this.incrementFlagCount(valueFlag.valueFlagType);
              }
              else if (question.answer &&
                question.answer.intAnswer &&
                valueFlag.numericMaximumValue &&
                question.answer.intAnswer >= valueFlag.numericMaximumValue) {
                reportRecord.answerText = question.answer.displayText;
                reportRecord.safeAnswerText = this.cleanHtml(question.answer.displayText)
                reportRecord.valueFlagType.push(valueFlag.valueFlagType);
                reportRecord.valueFlagText.push(valueFlag.valueFlagText);
                this.incrementFlagCount(valueFlag.valueFlagType);
              }
              else if (question.answer &&
                question.answer.intAnswer &&
                valueFlag.numericMinimumValue &&
                question.answer.intAnswer <= valueFlag.numericMinimumValue) {
                reportRecord.answerText = question.answer.displayText;
                reportRecord.safeAnswerText = this.cleanHtml(question.answer.displayText)
                reportRecord.valueFlagType.push(valueFlag.valueFlagType);
                reportRecord.valueFlagText.push(valueFlag.valueFlagText);
                this.incrementFlagCount(valueFlag.valueFlagType);
              }
            }
          });
        }

        reportRecords.push(reportRecord);
      });

      this.summaryQuestions = [];
      const uniqueModules = [...new Map(result.map(question => [question['moduleId'], question])).values()];
      uniqueModules.forEach(question => {
        const module = {} as SummaryModule
        module.hidden = false;
        module.id = question.moduleId;
        module.name = question.moduleName;
        module.displayOrder = question.displayOrder;
        module.categories = [] as SummaryCategory[];
        this.summaryQuestions.push(module);
      });

      const uniqueCategories = [...new Map(result.map(question => [question['categoryId'], question])).values()];
      uniqueCategories.forEach(question => {
        const category = {} as SummaryCategory;
        category.id = question.categoryId
        category.hidden = false;
        category.name = question.categoryName;
        question.displayOrder = question.displayOrder;
        category.reportRecords = reportRecords.filter(x => x.categoryName == category.name);
        this.summaryQuestions.find(module => module.id == question.moduleId).categories.push(category);
      });

      this.moduleCount = new Set(reportRecords.filter(x => x.moduleName).map(x => x.moduleName)).size;
      this.categoryCount = new Set(reportRecords.filter(x => x.categoryName).map(x => x.categoryName)).size;

      this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/Assessment%20Summary%20Header").subscribe(result => {
        this.surveySummaryDynamicContentHeader = result;
        this.surveySummaryDynamicContentHeader.content = this.surveySummaryDynamicContentHeader.content;
        this.surveySummaryHeaderSafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.surveySummaryDynamicContentHeader.content);
      }, error => {
        console.error(error)
        this.surveySummaryDynamicContentHeader = {} as DynamicContent;
        this.surveySummaryDynamicContentHeader.content = "An error occurred attempting to load content for this page.";
      });

      this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/Assessment%20Summary%20Footer").subscribe(result => {
        this.surveySummaryDynamicContentFooter = result;
        this.surveySummaryDynamicContentFooter.content = this.surveySummaryDynamicContentFooter.content;
        this.surveySummaryFooterSafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.surveySummaryDynamicContentFooter.content);
      }, error => {
        console.error(error)
        this.surveySummaryDynamicContentFooter = {} as DynamicContent;
        this.surveySummaryDynamicContentFooter.content = "An error occurred attempting to load content for this page.";
      });

      this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/Schedule%20Meeting").subscribe(result => {
        this.scheduleMeetingDynamicContent = result;
        this.scheduleMeetingSafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.scheduleMeetingDynamicContent.content);
      }, error => {
        console.error(error)
        this.scheduleMeetingDynamicContent = {} as DynamicContent;
        this.scheduleMeetingDynamicContent.content = "An error occurred attempting to load content for this page.";
      });

      // get the survey settings
      url = this.baseUrl + "api/survey/module/" + result[0].moduleId;

      this.http.get<Survey>(url).subscribe(result => {
        this.surveyName = result.name;
        this.showSurveySummary = result.showSurveySummary;
        this.showGrpScore = result.showGrpScore;
        this.showSurveyFlags = result.showSummaryFlags;

        this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/Assessment%20Summary").subscribe(result => {
          this.surveySummaryDynamicContent = result;
          this.surveySummaryDynamicContent.content = this.surveySummaryDynamicContent.content.replace(/{{surveyName}}/g, this.surveyName);
          this.surveySummarySafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.surveySummaryDynamicContent.content);
        }, error => {
          console.error(error)
          this.surveySummaryDynamicContent = {} as DynamicContent;
          this.surveySummaryDynamicContent.content = "An error occurred attempting to load content for this page.";
        });
      },
        error => console.error(error));

      // get the answer set detail
      url = this.baseUrl + "api/answerset/" + result[0].answer.answerSetId;

      this.http.get<AnswerSet>(url).subscribe(result => {
        this.score = result.score;
        this.surveyCompletionDate = result.finishDate;
      },
        error => console.error(error));
    },
      error => console.error(error));
  }

  incrementFlagCount(valueFlagType: number) {
    if (valueFlagType == 1) {
      this.greenFlagCount++;
    }
    else if (valueFlagType == 2) {
      this.yellowFlagCount++;
    }
    else if (valueFlagType == 3) {
      this.redFlagCount++;
    }
  }

  isBest(flag: ValueFlag, inFlags: ValueFlag[]) {
    if (flag.numericMaximumValue !== null) {
      const maximum: number = Math.max.apply(Math, inFlags.map(function (o) { return o.numericMaximumValue; }))
      return maximum === flag.numericMaximumValue;
    }
    else if (flag.numericMinimumValue !== null) {
      const minimum: number = Math.min.apply(Math, inFlags.map(function (o) { return o.numericMinimumValue; }))
      return minimum === flag.numericMinimumValue;
    }
    return true;
  }

  ifMultipleChoiceQuestion(reportRecord: ReportRecord) {
    return reportRecord?.multipleChoiceAnswers?.length > 0;
  }

  toggleModule(module: SummaryModule) {
    module.hidden = !module.hidden;
  }

  toggleCategory(category: SummaryCategory) {
    category.hidden = !category.hidden;
  }

  expandAll() {
    this.summaryQuestions.forEach(x => {
      x.hidden = false;
      x.categories.forEach(y => {
        y.hidden = false;
      });
    });
  }

  collapseAll() {
    this.summaryQuestions.forEach(x => {
      x.hidden = true;
      x.categories.forEach(y => {
        y.hidden = true;
      });
    });
  }

  public async savePDF(surveyCompletionDate) {
    this.isCreatingPdf = true;
    this.expandAll();
    setTimeout(() => {
      const title = this.surveyName.concat(" Assessment Summary");

      const header = this.headerElement.nativeElement as HTMLElement;
      let content = header.innerHTML;

      const counts = this.countsElement.nativeElement as HTMLElement;
      content = content.concat(counts.innerHTML);

      const length = this.reportQuestions.length;
      for (let i = 0; i < length; i++) {
        const element = this.reportQuestions.get(i);
        content = content.concat(element.nativeElement.innerHTML);
      };

      const footer = this.footerElement.nativeElement as HTMLElement;
      content = content.concat(footer.innerHTML);

      let html = htmlToPdfmake(content);

      const documentDefinition = {
        content: [
          html,
        ],
        title: title,
        subject: "Summary Report",
        author: "Partnership for Assessment and Acreditation of Scientific Practice",
        pageSize: "LETTER" as PageSize,
        pageOrientation: 'portrait' as PageOrientation,
        pageMargins: [40, 60, 100, 60] as Margins
      };

      let filename = this.surveyName.replace(/ /gi, '_');
      filename = filename.replace(/([^A-Za-z0-9_]+)/gi, '');
      filename += "_Summary_Report-";
      filename += surveyCompletionDate.substr(0, 10);

      pdfMake.createPdf(documentDefinition).download(filename);

      this.isCreatingPdf = false;    }, 2000);
  }
}
