import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  forgotPaswordForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  message: string;
  error = '';
  isReset: Boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    this.forgotPaswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotPaswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPaswordForm.invalid) {
      return;
    }

    this.loading = true;

    var url = this.baseUrl + "api/user/password/" + this.f.email.value;

    this.http.get(url).subscribe(result => {
      this.isReset = true;
      this.loading = false;
    }, error => {
      this.message = "Unable to reset the password.";
      console.error(error);
      this.loading = false;
    });
  }
}
