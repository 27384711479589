import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  message = "Are you sure?"
  safeDynamicContent: SafeHtml;
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      this.safeDynamicContent = this.sanitizer.bypassSecurityTrustHtml(this.message);
      if (data.buttonText) {
        this.confirmButtonText = this.confirmButtonText;
        if (data.buttonText !== null && data.buttonText.ok !== null) {
          this.confirmButtonText = data.buttonText.ok;
        }

        this.cancelButtonText = this.cancelButtonText;
        if (data.buttonText !== null && data.buttonText.cancel !== null) {
          this.cancelButtonText = data.buttonText.cancel;
        }
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
