import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { Organization } from '../../interfaces/organization';

@Component({
  selector: 'organization-details-dialog',
  templateUrl: './organization-details-dialog.component.html',
  styleUrls: ['./organization-details-dialog.component.css']
})

export class OrganizationDetailsDialogComponent implements OnInit {
  organization: Organization;
  form: UntypedFormGroup;
  isAdmin: boolean;
  title = 'Add';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<OrganizationDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    this.organization = data.organization;

    this.form = this.formBuilder.group({
      name: new UntypedFormControl(this.organization.name, Validators.required),
      descption: new UntypedFormControl(this.organization.description),
    });
  }

  ngOnInit() {
    // Get the organization from the server.
    const url = this.baseUrl + "api/organization/" + this.organization.id;
    this.http.get<Organization>(url).subscribe(result => {
      this.organization = result;
      this.title = 'Edit';
    },
      error => console.error(error));

    this.form = this.formBuilder.group({
      name: new UntypedFormControl(this.organization.name, Validators.required),
      description: new UntypedFormControl(this.organization.description),
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };

    const dialogRef = this.dialog.open(OrganizationDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }

  close() {
    this.dialogRef.close();
  }

  getControls() {
    return (this.form.controls.flags as UntypedFormArray).controls;
  }

  save() {
    const returnSettings = {
      name: this.form.value.name,
      description: this.form.value.description,
      id: this.organization.id,
    };

    this.dialogRef.close({
      settings: returnSettings
    });
  }
}
