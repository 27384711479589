import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(private http: HttpClient) {
  }

  public downloadFile(path: string): Observable<Blob> {
    return this.http.get(path, {
      responseType: 'blob'
    });
  }
}  
