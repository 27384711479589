import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DynamicContent } from '../../interfaces/dynamicContent';
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: 'dynamic-content-list',
  templateUrl: './dynamic-content-list.component.html',
  styleUrls: ['./dynamic-content-list.component.css']
})

export class DynamicContentListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'actions'];
  dynamicContent: DynamicContent[];
  datasource: MatTableDataSource<DynamicContent>;
  isAdminUser = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    const url = this.baseUrl + "api/dynamiccontent";
    this.http.get<DynamicContent[]>(url).subscribe(result => {
      this.dynamicContent = result;
      this.datasource = new MatTableDataSource<DynamicContent>(this.dynamicContent);
    },
      error => console.error(error));
  }

  editDynamicContent(dynamicContent: DynamicContent) {
    this.router.navigate(['/dynamic-content/' + dynamicContent.id]);
  }
}
