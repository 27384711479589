import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { SecurityFlag } from '../../interfaces/securityFlag';
import { Role } from '../../interfaces/role';

@Component({
  selector: 'role-details-dialog',
  templateUrl: './role.details.dialog.component.html',
  styleUrls: ['./role.details.dialog.component.css']
})

export class RoleDetailsDialogComponent implements OnInit {
  role: Role;
  flags: SecurityFlag[] = [];
  roleForm: UntypedFormGroup;
  isAdmin: boolean;
  title = 'Add';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<RoleDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    this.role = data.role;
    this.isAdmin = this.role.priority === 1;

    this.roleForm = this.formBuilder.group({
      name: new UntypedFormControl(this.role.name, Validators.required),
      adminRole: new UntypedFormControl(this.isAdmin),
      flags: new UntypedFormArray([]),
    });
  }

  ngOnInit() {
    // Get the role from the server.
    const url = this.baseUrl + "api/role/" + this.role.id;
    this.http.get<Role>(url).subscribe(result => {
      this.role = result;
      this.title = 'Edit';
    },
      error => console.error(error));

    // Get all security flags from the server.
    this.http.get<SecurityFlag[]>(this.baseUrl + "api/securityflag").subscribe(result => {
      this.flags = result;

      const flags = this.flags.map(() => new UntypedFormControl(false));
      if (this.role.flags && this.role.flags.length > 0) {
        for (let i = 0; i < flags.length; i++) {
          if (this.role.flags.filter(a => a.id === this.flags[i].id).length > 0) {
            flags[i].setValue(true);
          }
        }
      }

      this.roleForm = this.formBuilder.group({
        name: new UntypedFormControl(this.role.name, Validators.required),
        adminRole: new UntypedFormControl(this.isAdmin),
        flags: new UntypedFormArray(flags),
      });

    },
      error => console.error(error));
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };

    const dialogRef = this.dialog.open(RoleDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }

  close() {
    this.dialogRef.close();
  }

  toggle(event) {
    // this.isAdmin = !this.isAdmin;
  }

  getControls() {
    return (this.roleForm.controls.flags as UntypedFormArray).controls;
  }

  save() {
    const flags: SecurityFlag[] = [];
    if (this.isAdmin) {
      for (let i = 0; i < this.roleForm.value.flags.length; i++) {
        if (this.roleForm.value.flags[i] === true) {
          flags.push(this.flags[i]);
        }
      }
    }

    const returnSettings = {
      name: this.roleForm.value.name,
      admin: this.roleForm.value.adminRole,
      flags: flags,
      id: this.role.id,
    };

    this.dialogRef.close({
      settings: returnSettings
    });
  }
}
