import { ChangeDetectorRef, Component, Inject, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { Module } from '../../interfaces/module';
import { Factor } from '../../interfaces/factor';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Category } from '../../interfaces/category';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { DecimalValidationDirective } from '../directives/decimalValidation.directive';

@Component({
  selector: "module-edit",
  templateUrl: './module-edit.component.html',
  styleUrls: ['./module-edit.component.less']
})

export class ModuleEditComponent implements OnInit {
  title: string;
  @Input() module: Module;
  @Input() surveyVersionId: number;
  @Output() moduleChange = new EventEmitter<Module>();
  @Output() editComplete: EventEmitter<number> = new EventEmitter();
  @Output() createComplete: EventEmitter<Module> = new EventEmitter();
  @Output() reorderComplete: EventEmitter<number> = new EventEmitter();
  moduleloaded = false;
  form: UntypedFormGroup;
  factors: Factor[];
  factorsloaded = false;
  editMode = false;
  categories: Category[];
  categoriesloaded = false;
  categoriesDataSource: MatTableDataSource<Category> = new MatTableDataSource<Category>([]);
  displayedColumns: string[] = ['actions', 'category', 'description'];
  faBars = faBars;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    // initialize the form
    this.createForm();

    if (this.module) {
      this.editMode = true;

      // update the form with the module value
      this.updateForm();

      this.title = "Edit Module - " + this.module.name;
    }
    else {
      // create an empty object from the Module interface
      this.module = {} as Module;
      this.editMode = false;
      this.title = "Create a new Module";
    }
  }

  createForm() {
    const factorsUrl = this.baseUrl + "api/module/Factors"
    this.http.get<Factor[]>(factorsUrl).subscribe(result => {
      this.factors = result;
      this.factorsloaded = true;

    }, error => console.error(error));

    this.form = this.fb.group({
      name: ['', Validators.required],
      description: '',
      factorid: 1,
      weight: [null, Validators.min(0)],
    });

    if (this.module) {
      this.http.get<Category[]>(this.baseUrl + "api/category/module/" + this.module.id).subscribe(result => {
        this.categories = result;
        this.categoriesloaded = this.categories && (this.categories.length > 0);
        this.categoriesDataSource = new MatTableDataSource<Category>(this.categories);
        this.changeDetectorRef.detectChanges();
      },
        error => console.error(error));
    }
  }

  updateForm() {
    this.form.patchValue(this.module);
  }

  onSubmit() {
    // build a temporary module object from form values
    const tempModule = {} as Module;
    tempModule.name = this.form.value.name;
    tempModule.description = this.form.value.description;
    tempModule.factorId = +this.form.value.factorid;
    tempModule.surveyVersionId = this.surveyVersionId;
    tempModule.weight = this.form.value.weight;

    const url = this.baseUrl + "api/module";

    if (this.editMode) {
      tempModule.id = this.module.id;

      this.http
        .post<Module>(url, tempModule)
        .subscribe(res => {
          this.module = res;
          console.log("Module " + this.module.id + " has been updated.");
          this.moduleChange.emit(this.module);
          this.editComplete.emit(1);
        }, error => console.log(error));
    }
    else {
      this.http
        .put<Module>(url, tempModule)
        .subscribe(res => {
          console.log("Module " + res.id + " has been created.");
          this.createComplete.emit(res);
        }, error => console.log(error));
    }

    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  onBack() {
    if (this.editMode) {
      this.editComplete.emit(0);
    }
    else {
      this.createComplete.emit(null);
    }
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onListDrop(event: CdkDragDrop<Category[]>) {
    // Swap the elements around
    const prevIndex = this.categories.findIndex((d) => d === event.item.data);
    moveItemInArray(this.categories, prevIndex, event.currentIndex);
    this.categoriesDataSource = new MatTableDataSource<Category>(this.categories);

    // Update the display order
    this.categories.map(x => {
      x.displayOrder = this.categories.findIndex(question => question.id === x.id) + 1;
      return x
    });

    // Save changes to the server
    this.http.post<any>(`api/category/order`, this.categories.map(x => {
      return { DisplayOrder: x.displayOrder, Id: x.id }
    })).subscribe(result => {
      this.reorderComplete.emit(2);
      console.log("POST is successful ", result);
    },
      error => console.error(error));
  }
}
