import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuthenticationService } from "../../services/authentication.service";
import { FileService } from "../../services/file.service";
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import * as fileSaver from 'file-saver';
import { SurveyResult } from '../../interfaces/surveyResult';
import { LocalizedDatePipe } from "../../app/helpers/localized-date.pipe";
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ScoreSummaryDialogComponent } from '../score-summary-dialog/score-summary-dialog.component';

@Component({
  selector: 'survey-results',
  templateUrl: './survey-results.component.html',
  styleUrls: ['./survey-results.component.css']
})

export class SurveyResultsComponent implements OnInit {

  displayedColumns: string[] = ['select', 'name', 'email', 'startDate', 'finishDate', 'surveyName', 'version', 'completionPercentage', 'surveyScore', 'actions'];
  surveys: SurveyResult[];
  datasource: MatTableDataSource<SurveyResult>;
  isAdminUser = false;
  selection = new SelectionModel<SurveyResult>(true, []);
  filterCriteria = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private fileService: FileService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParamMap.get('u')) {
      this.filterCriteria = this.activatedRoute.snapshot.queryParamMap.get('u');
    }

    const url = this.baseUrl + "api/survey/result";
    this.http.get<SurveyResult[]>(url).subscribe(result => {
      this.surveys = result;
      this.surveys.forEach(x => x.name = x.firstName + " " + x.lastName);
      this.sort.sort(({ id: 'startDate', start: 'desc' }) as MatSortable);
      this.datasource = new MatTableDataSource<SurveyResult>(this.surveys);
      this.datasource.paginator = this.paginator;
      this.datasource.filterPredicate = this.surveyGridFilter();
      this.datasource.filter = this.filterCriteria;
      this.datasource.sort = this.sort;
      this.datasource.sortingDataAccessor = (data, sortHeaderId) => {
        if (sortHeaderId !== 'completionPercentage' && sortHeaderId !== 'surveyScore' && !data[sortHeaderId]) {
          return this.sort.direction === "asc" ? '3' : '1';
        }
        switch (sortHeaderId) {
          case 'startDate': {
            return '2' + new Date(data.startDate).toISOString();
          }
          case 'finishDate': {
            return '2' + new Date(data.finishDate).toISOString();
          }
          case 'completionPercentage': {
            return '2' + data[sortHeaderId].toString().padStart(3, '0');
          }
          case 'surveyScore': {
            return data[sortHeaderId];
          }
          default: {
            return '2' + data[sortHeaderId].toLocaleLowerCase();
          }
        };
      }
    },
      error => console.error(error));
  }

  viewScoreSummary(survey: SurveyResult) {
    this.dialog.open(ScoreSummaryDialogComponent, {
      data: survey
    });
  }

  viewSurvey(survey: SurveyResult) {
    this.router.navigate(["/survey/summary/"], { queryParams: { id: survey.id, u: survey.applicationUserId } });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.datasource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.datasource.data.forEach(row => this.selection.select(row));
  }

  unfilter() {
    this.filterCriteria = '';
    this.datasource.filter = this.filterCriteria;
  }

  exportChecked() {
    if (this.selection.hasValue()) {
      const jsonIds = JSON.stringify(this.selection.selected.map(x => x.id));

      this.fileService.downloadFile('api/answerset/export?criteria=' + encodeURIComponent(jsonIds)).subscribe(response => {
        const blob = new Blob([response], { type: 'application/zip' });
        fileSaver.saveAs(blob, 'assessments-export.zip');
      }), error => console.log('Error downloading the file: ' + error),
        () => console.info('File downloaded successfully');
    }
  }

  isLastSurvey(surveyResult: SurveyResult) {
    if (!surveyResult) {
      return false;
    }
    const userSurveys: SurveyResult[] = this.surveys
      .filter(s => s.applicationUserId === surveyResult.applicationUserId && s.surveyId === surveyResult.surveyId)
      .sort((a,b) => (a.id < b.id) ? 1 : -1);
    if (userSurveys &&
      userSurveys.length > 0 &&
      userSurveys[0].finishDate &&
      userSurveys[0].id === surveyResult.id) {
      return true;
    }
    return false;
  }

  unfinishSurvey(survey: SurveyResult) {
    if (!survey) {
      return false;
    }
    const url = this.baseUrl + "api/answerset/" + survey.id + "/date";
    this.http.delete(url).subscribe(() => {
      const changed: SurveyResult = this.surveys.find(s => s.id === survey.id);
      if (changed) {
        changed.finishDate = null;
      }
    },
      error => console.error(error));
  }

  isInProgressSurvey(surveyResult: SurveyResult) {
    if (!surveyResult) {
      return false;
    }
    const userSurveys: SurveyResult[] = this.surveys
      .filter(s => s.applicationUserId === surveyResult.applicationUserId && s.surveyId === surveyResult.surveyId)
      .sort((a, b) => (a.id < b.id) ? 1 : -1);
    if (userSurveys &&
      userSurveys.length > 0 &&
      userSurveys[0].finishDate === null &&
      userSurveys[0].id === surveyResult.id) {
      return true;
    }
    return false;
  }

  deleteSurvey(survey: SurveyResult) {
    if (!survey) {
      return false;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete this survey?",
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const url = this.baseUrl + "api/answerset/" + survey.id;
        this.http.delete(url).subscribe(() => {
          this.surveys = this.surveys.filter(s => s.id !== survey.id);
          this.datasource = new MatTableDataSource<SurveyResult>(this.surveys);
          this.datasource.filter = this.filterCriteria;
          this.datasource.sort = this.sort;
          this.datasource.paginator = this.paginator;
        },
          error => console.error(error));
      }
    });
  }

  surveyGridFilter(): (data: SurveyResult, filter: string) => boolean {
    const filterFunction = function (data: SurveyResult, filter: string): boolean {
      localStorage.setItem('sweepstakesGridFilterCriteria', filter);

      if (filter && filter.substring.length > 0) {
        return data.name.toString().toLowerCase().includes(filter) ||
          data.version.toString().toLowerCase().includes(filter) ||
          data.surveyName.toString().toLowerCase().includes(filter) ||
          data.email.toString().toLowerCase().includes(filter);
      }

      return true;
    }
    return filterFunction;
  }

  applyFilter(filterValue: string) {
    this.filterCriteria = filterValue.trim().toLowerCase();
    this.datasource.filter = this.filterCriteria;
  }
}
