import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TargetAudience } from '../../interfaces/targetaudience';

@Component({
  selector: 'target-audience-details-dialog',
  templateUrl: './target-audience-details-dialog.component.html',
  styleUrls: ['./target-audience-details-dialog.component.css']
})

export class TargetAudienceDetailsDialogComponent implements OnInit {
  targetAudience: TargetAudience;
  form: UntypedFormGroup;
  title = 'Add';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<TargetAudienceDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    @Inject('BASE_URL') private baseUrl: string) {
    this.targetAudience = data.targetAudience;

    this.form = this.formBuilder.group({
      name: new UntypedFormControl(this.targetAudience.name, Validators.required),
      descption: new UntypedFormControl(this.targetAudience.description),
    });
  }

  ngOnInit() {
    if (this.targetAudience.name) {
      this.title = 'Edit';
    }

    this.form = this.formBuilder.group({
      name: new UntypedFormControl(this.targetAudience.name, Validators.required),
      description: new UntypedFormControl(this.targetAudience.description),
    });
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };

    const dialogRef = this.dialog.open(TargetAudienceDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }

  close() {
    this.dialogRef.close();
  }

  getControls() {
    return (this.form.controls.flags as UntypedFormArray).controls;
  }

  save() {
    const returnSettings = {
      name: this.form.value.name,
      description: this.form.value.description,
      id: this.targetAudience.id,
    };

    this.dialogRef.close({
      settings: returnSettings
    });
  }
}
