import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { DatePipe } from '@angular/common';

// Internationalization
import { LocalizedDatePipe } from '../app/helpers/localized-date.pipe';
import { registerLocaleData } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import en from '@angular/common/locales/en';
import de from '@angular/common/locales/de';
import hy from '@angular/common/locales/hy';
import fr from '@angular/common/locales/fr';
import it from '@angular/common/locales/it';
import es from '@angular/common/locales/es';
import ja from '@angular/common/locales/ja';

registerLocaleData(en);
registerLocaleData(de);
registerLocaleData(hy);
registerLocaleData(fr);
registerLocaleData(it);
registerLocaleData(es);
registerLocaleData(ja);

// Angular Material UI
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuillModule } from 'ngx-quill'

// App components
import { AuthGuard } from './helpers/auth.guard';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { NoCacheHeadersInterceptor } from './helpers/nocache.interceptor';
import { NgxTextDiffModule } from "../ngx-text-diff/ngx-text-diff.module";

import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { AnswerEditComponent } from './answer/answer-edit.component';
import { AuditLogDetailComponent } from './admin/audit-log-detail.component';
import { AuditLogListComponent } from './admin/audit-log-list.component';
import { CategoryComponent } from './category/category.component';
import { CategoryEditComponent } from './category/category-edit.component';
import { ChangePasswordComponent } from './user/change-password.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmEmailComponent } from './user/confirm-email.component';
import { ContactComponent } from './about/contact.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { DynamicContentListComponent } from './dynamic-content/dynamic-content-list.component';
import { DynamicContentComponent } from './dynamic-content/dynamic-content.component';
import { FaqComponent } from './about/faq.component';
import { ForgotPasswordComponent } from './user/forgot-password.component';
import { InvitationComponent } from './invitation/invitation.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { ModuleComponent } from './module/module.component';
import { ModuleEditComponent } from './module/module-edit.component';
import { MultipleChoiceAnswerDetailsDialogComponent } from './question/multplechoiceanswer.details.dialog.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { OrganizationDetailsDialogComponent } from './organization/organization-details-dialog.component';
import { OrganizationMergeDialogComponent } from './organization/organization-merge-dialog.component';
import { OrganizationListComponent } from './organization/organization-list.component';
import { PaaspCertificationComponent } from './about/paasp.certification.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { PolicyComponent } from './about/policy.component';
import { QuestionComponent } from './question/question.component';
import { QuestionListComponent } from './question/question-list.component';
import { QuestionEditComponent } from './question/question-edit.component';
import { RegisterComponent } from './user/register.component';
import { RegistrationValidationComponent } from './registration-validation/registration-validation.component';
import { ResetPasswordComponent } from './user/reset-password.component';
import { RoleDetailsDialogComponent } from './role/role.details.dialog.component';
import { RoleListComponent } from './role/role-list.component';
import { ScoreSummaryDialogComponent } from './score-summary-dialog/score-summary-dialog.component';
import { SpinnerOverlayComponent } from './spinner-overlay.component';
import { SurveyCompletionComponent } from './survey/survey-completion.component';
import { SurveyComponent } from './survey/survey.component';
import { SurveyEditComponent } from './survey/survey-edit.component';
import { SurveyFlagDetailsDialogComponent } from './survey/surveyflag.details.dialog.component';
import { SurveyListComponent } from './survey/survey-list.component';
import { SurveyPastListComponent } from './survey/survey-past-list.component';
import { SurveyResultsComponent } from './survey/survey-results.component';
import { SurveySummaryComponent } from './survey/survey-summary.component';
import { TargetAudienceDetailsDialogComponent } from './target-audience/target-audience-details-dialog.component';
import { TargetAudienceListComponent } from './target-audience/target-audience-list.component';
import { UploadDialogComponent } from './upload/upload-dialog.component';
import { UploadListComponent } from './upload/upload-list.component';
import { UserActivityComponent } from './user/user-activity.component';
import { UserListComponent } from './user/user-list.component';
import { UserSurveyAccessComponent } from './user/user-survey-access.component';
import { UserTypeDetailsDialogComponent } from './user-type/user-type-details-dialog.component';
import { UserTypeListComponent } from './user-type/user-type-list.component';
import { ValueFlagDetailsDialogComponent } from './question/valueflag.details.dialog.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AnswerEditComponent,
    AuditLogDetailComponent,
    AuditLogListComponent,
    CategoryComponent,
    CategoryEditComponent,
    ChangePasswordComponent,
    ConfirmationDialogComponent,
    ConfirmEmailComponent,
    ContactComponent,
    CookieConsentComponent,
    ConfirmationDialogComponent,
    DynamicContentComponent,
    DynamicContentListComponent,
    FaqComponent,
    ForgotPasswordComponent,
    InvitationComponent,
    LoginComponent,
    LogoutComponent,
    ModuleComponent,
    ModuleEditComponent,
    MultipleChoiceAnswerDetailsDialogComponent,
    NavMenuComponent,
    PaaspCertificationComponent,
    PageNotFoundComponent,
    PolicyComponent,
    OrganizationDetailsDialogComponent,
    OrganizationMergeDialogComponent,
    OrganizationListComponent,
    QuestionComponent,
    QuestionListComponent,
    QuestionEditComponent,
    RegisterComponent,
    RegistrationValidationComponent,
    ResetPasswordComponent,
    RoleDetailsDialogComponent,
    RoleListComponent,
    ScoreSummaryDialogComponent,
    SpinnerOverlayComponent,
    SurveyCompletionComponent,
    SurveyComponent,
    SurveyEditComponent,
    SurveyFlagDetailsDialogComponent,
    SurveyListComponent,
    SurveyPastListComponent,
    SurveyResultsComponent,
    SurveySummaryComponent,
    TargetAudienceDetailsDialogComponent,
    TargetAudienceListComponent,
    UploadDialogComponent,
    UploadListComponent,
    UserActivityComponent,
    UserListComponent,
    UserSurveyAccessComponent,
    UserTypeDetailsDialogComponent,
    UserTypeListComponent,
    ValueFlagDetailsDialogComponent,
    LocalizedDatePipe,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en'
    }),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        imageResize: true,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          // ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          // [{ 'direction': 'rtl' }],                         // text direction
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link', 'image' /*, 'video'*/] // link and image, video
        ]
      }
    }),
    HttpClientModule,
    FormsModule,
    NgxTextDiffModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'about/policy', component: PolicyComponent },
      { path: 'about/certification', component: PaaspCertificationComponent },
      { path: 'about/faq', component: FaqComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'audit-log', component: AuditLogListComponent },
      { path: 'audit-log/:id', component: AuditLogDetailComponent },
      { path: 'category/create/:id', component: CategoryEditComponent, canActivate: [AuthGuard] },
      { path: 'category/:id', component: CategoryComponent, canActivate: [AuthGuard] },
      { path: 'confirm-email/:id', component: ConfirmEmailComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'dynamic-content', component: DynamicContentListComponent, canActivate: [AuthGuard] },
      { path: 'dynamic-content/:id', component: DynamicContentComponent, canActivate: [AuthGuard] },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'home', component: SurveyListComponent },
      { path: 'invitation', component: InvitationComponent, canActivate: [AuthGuard] },
      { path: 'login', component: LoginComponent },
      { path: 'timeout', component: LoginComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'module/create/survey/:sid', component: ModuleEditComponent, canActivate: [AuthGuard] },
      { path: 'module/:id', component: ModuleComponent, canActivate: [AuthGuard] },
      { path: 'organization', component: OrganizationListComponent, canActivate: [AuthGuard] },
      { path: 'organization-types', component: TargetAudienceListComponent, canActivate: [AuthGuard] },
      { path: 'question/:id', component: QuestionComponent, canActivate: [AuthGuard] },
      { path: 'register', component: RegisterComponent },
      { path: 'registration-validation', component: RegistrationValidationComponent, canActivate: [AuthGuard] },
      { path: 'reset-password/:id', component: ResetPasswordComponent },
      { path: 'role', component: RoleListComponent, canActivate: [AuthGuard] },
      { path: 'survey', component: SurveyListComponent },
      { path: 'survey/results', component: SurveyResultsComponent, canActivate: [AuthGuard] },
      { path: 'survey/create', component: SurveyEditComponent, canActivate: [AuthGuard] },
      { path: 'survey/user', component: SurveyPastListComponent, canActivate: [AuthGuard] },
      { path: 'survey/summary', component: SurveySummaryComponent, canActivate: [AuthGuard] },
      { path: 'survey/:id', component: SurveyComponent },
      { path: 'password/account/:id', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'account/:id', component: RegisterComponent, canActivate: [AuthGuard] },
      { path: 'upload', component: UploadListComponent, canActivate: [AuthGuard] },
      { path: 'user/:id/activity', component: UserActivityComponent, canActivate: [AuthGuard] },
      { path: 'user', component: UserListComponent, canActivate: [AuthGuard] },
      { path: 'user-survey-access', component: UserSurveyAccessComponent, canActivate: [AuthGuard] },
      { path: 'professional-categories', component: UserTypeListComponent, canActivate: [AuthGuard] },
      { path: '**', component: PageNotFoundComponent }
    ], {
    scrollPositionRestoration: 'enabled'
}),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    // Material UI
    DragDropModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    [BnNgIdleService],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
