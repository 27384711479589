import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuthenticationService } from "../../services/authentication.service";
import { MatSort, Sort } from '@angular/material/sort';
import { SurveyResult } from '../../interfaces/surveyResult';
import { LocalizedDatePipe } from "../../app/helpers/localized-date.pipe";
import { MatDialog } from '@angular/material/dialog';
import { ScoreSummaryDialogComponent } from '../score-summary-dialog/score-summary-dialog.component';

@Component({
  selector: 'survey-past-list',
  templateUrl: './survey-past-list.component.html',
  styleUrls: ['./survey-past-list.component.css']
})

export class SurveyPastListComponent implements OnInit {

  displayedColumns: string[] = ['surveyName', 'startDate', 'finishDate', 'score', 'actions'];
  surveys: SurveyResult[];
  datasource: MatTableDataSource<SurveyResult>;
  isAdminUser = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();
  }

  ngOnInit() {
    const url = this.baseUrl + "api/survey/past";
    this.http.get<SurveyResult[]>(url).subscribe(result => {
      this.surveys = result.filter(x => x.completionPercentage === 100);
      this.datasource = new MatTableDataSource<SurveyResult>(this.surveys);
      this.datasource.sort = this.sort;
      this.datasource.sortingDataAccessor = (data, sortHeaderId) => {
        switch (sortHeaderId) {
          case 'startDate': {
            return '2' + new Date(data.startDate).toISOString();
          }
          case 'finishDate': {
            return '2' + new Date(data.finishDate).toISOString();
          }
         default: {
            return '2' + data[sortHeaderId].toLocaleLowerCase();
          }
        };
      }
    },
      error => console.error(error));
  }

  viewSurvey(survey: SurveyResult) {
    this.router.navigate(["/survey/summary/"], { queryParams: { id: survey.id }});
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  viewScoreSummary(survey: SurveyResult) {
    this.dialog.open(ScoreSummaryDialogComponent, {
      data: survey
    });
  }
}
