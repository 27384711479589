import { Component, Inject, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators, ValidatorFn } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MultipleChoiceAnswerDetailsDialogComponent } from './multplechoiceanswer.details.dialog.component';
import { ValueFlagDetailsDialogComponent } from './valueflag.details.dialog.component';
import { DomSanitizer } from '@angular/platform-browser'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { moveItemInArray , CdkDragDrop } from '@angular/cdk/drag-drop';
import { ValueFlag } from '../../interfaces/valueFlag';
import { MultipleChoiceAnswer } from '../../interfaces/multipleChoiceAnswer';
import { Question } from '../../interfaces/question';
import { AnswerType } from '../../interfaces/answertype';

@Component({
  selector: "question-edit",
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.css']
})

export class QuestionEditComponent implements OnInit {
  title: string;
  @Input() question: Question;
  @Input() categoryId: number;
  @Output() questionChange = new EventEmitter<Question>();
  @Output() editComplete: EventEmitter<number> = new EventEmitter();
  @Output() createComplete: EventEmitter<Question> = new EventEmitter();
  parentQuestion: Question;
  form: UntypedFormGroup;
  answertypes: AnswerType[];
  answertypesloaded = false;
  answerSelectionsLoaded = false;
  primaryAnswerSelections: AnswerType[];
  primaryAnswerSelectionId: number;
  secondaryAnswerSelections: AnswerType[];
  secondaryAnswerSelectionId: number;
  rangetype = 'none';
  displayrange = false;
  redflagtype = 'none';
  displayValueFlags = false;
  displayMultiChoice = false;
  displayFollowUpQuestions = false;

  valueFlagsDataSource: MatTableDataSource<ValueFlag> = new MatTableDataSource<ValueFlag>([]);
  valueFlagsDisplayedColumns: string[] = ['valueFlagValueText', 'valueFlagTypeText', 'valueFlagText', 'actions'];
  multipleChoiceAnswersDataSource: MatTableDataSource<MultipleChoiceAnswer> = new MatTableDataSource<MultipleChoiceAnswer>([]);
  multipleChoiceAnswersDisplayedColumns: string[] = ['answerText', 'valueFlagTypeText', 'valueFlagText', 'actions'];
  followUpQuestionsDataSource: MatTableDataSource<Question> = new MatTableDataSource<Question>([]);
  followUpQuestionsDisplayedColumns: string[] = ['questionText', 'actions'];
  faPlus = faPlus;
  editMode: boolean;
  get followUpMode(): boolean { return !(this.parentQuestion === null || this.parentQuestion === undefined); }

  private static FLOAT_PATTERN = '^-?[0-9]\\d*(\\.\\d*)?$';
  private static INTEGER_PATTERN = '^-?[0-9]\\d*';

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    if (this.question) {
      this.createForm();

      if (this.followUpMode === true) {
        if (this.question.id) {
          this.title = "Edit Follow-up Question";
        }
        else {
          this.title = "Create Follow-up Question";
        }

        this.updateForm();
      }
      else {
        this.editMode = true;
        this.title = "Edit Question";
        this.updateForm();
      }
    }
    else {
      // create an empty object from the question interface
      this.title = "Create a new Question";
      this.question = {} as Question;
      this.question.multipleChoiceAnswers = [];
      this.question.valueFlags = [];
      this.question.categoryId = this.categoryId;
      this.createForm();
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  createForm() {
    this.primaryAnswerSelections = [
      { id: 2, name: "Text", description: "Text" } as AnswerType,
      { id: 3, name: "Integer", description: "Integer" } as AnswerType,
      { id: 4, name: "Float", description: "Float" } as AnswerType,
      { id: 5, name: "File Upload", description: "File Upload" } as AnswerType,
      { id: 6, name: "Multiple Selection", description: "Multiple Selection" } as AnswerType,
    ];
    this.answerSelectionsLoaded = true;

    const answerTypesUrl = this.baseUrl + "api/question/AnswerTypes"
    this.http.get<AnswerType[]>(answerTypesUrl).subscribe(result => {
      this.answertypes = result;

      // force a refresh
      this.answertypesloaded = true;
      this.onAnswerTypeChanged();
    }, error => console.error(error));

    this.form = this.fb.group({
      text: ['', Validators.required],
      description: '',
      answerSelectionId: 1,
      secondaryAnswerSelectionId: 0,
      answerminvalue: null,
      answermaxvalue: null,
      multiChoiceMultiAnswer: new UntypedFormArray([]),
      parentQuestionAnswer: [''],
      weight: [null, Validators.min(0)]
    }, { validator: [this.minMaxComparisonValidator, this.minMaxRangeComparisonValidator]});
  }

  updateForm() {
    if (this.form.value) {
      let selectionId = 1;
      switch (this.question.answerTypeId) {
        case 1:
          selectionId = 2;
          break;
        case 3:
          selectionId = 3;
          break;
        case 4:
          selectionId = 4;
          break;
        case 6:
          selectionId = 3;
          break;
        case 7:
          selectionId = 4;
          break;
        case 10:
          selectionId = 3;
          break;
        case 13:
          selectionId = 6;
          break;
        case 14:
          selectionId = 6;
          break;
        case 15:
          selectionId = 6;
          break;
        case 16:
          selectionId = 6;
          break;
        case 17:
          selectionId = 5;
          break;
        case 18:
          selectionId = 6;
          break;
      }
      this.setSecondaryAnswerTypes(selectionId);

      let multiChoiceAnswers = [];
      if (this.parentQuestion &&
        this.parentQuestion.answerType.startsWith('multichoice')) {
        this.parentQuestion.multipleChoiceAnswers.forEach(mca => {
          mca.safeAnswerText = this.sanitizer.bypassSecurityTrustHtml(mca.answerText);
        });
        multiChoiceAnswers = this.parentQuestion.multipleChoiceAnswers.map(() => new UntypedFormControl(false));
        if (this.parentQuestion.multipleChoiceAnswers && this.parentQuestion.multipleChoiceAnswers.length > 0) {
          if (this.question.parentFollowUpAnswer && this.question.parentFollowUpAnswer.length > 0) {
            if (this.question.parentFollowUpAnswer.indexOf("]") === -1) {
              this.question.parentFollowUpAnswer = "[" + this.question.parentFollowUpAnswer + "]";
            }

            const ids = JSON.parse(this.question.parentFollowUpAnswer);
            for (let i = 0; i < this.parentQuestion.multipleChoiceAnswers.length; i++) {
              if (ids.filter(a => a === this.parentQuestion.multipleChoiceAnswers[i].id).length > 0) {
                multiChoiceAnswers[i].setValue(true);
              }
            }
          }
        }
      }

      const parentFollowUpAnswer = this.question.parentFollowUpAnswer;

      if (this.question.followUpQuestions) {
        this.question.followUpQuestions.forEach(followUpQuestion => {
          followUpQuestion.safeText = this.sanitizer.bypassSecurityTrustHtml(followUpQuestion.text);
          if (followUpQuestion.answerType.startsWith('multichoice') && followUpQuestion.multipleChoiceAnswers) {
            followUpQuestion.multipleChoiceAnswers.forEach(mca => {
              mca.safeAnswerText = this.sanitizer.bypassSecurityTrustHtml(mca.answerText);
            });
          }
        });
      }

      this.secondaryAnswerSelectionId = this.question.answerTypeId;

      this.form = this.fb.group({
        text: [this.question.text || '', Validators.required],
        description: this.question.description || '',
        answerSelectionId: selectionId,
        secondaryAnswerSelectionId: this.secondaryAnswerSelectionId,
        answerminvalue: this.question.answerMinValue,
        answermaxvalue: this.question.answerMaxValue,
        multiChoiceMultiAnswer: new UntypedFormArray(multiChoiceAnswers),
        parentQuestionAnswer: parentFollowUpAnswer || '',
        weight: [this.question.weight, Validators.min(0)]
      });

      this.form.controls.parentQuestionAnswer.setValue(parentFollowUpAnswer);

      this.multipleChoiceAnswersDataSource = new MatTableDataSource<MultipleChoiceAnswer>(this.question.multipleChoiceAnswers);
      this.followUpQuestionsDataSource = new MatTableDataSource<Question>(this.question.followUpQuestions);
      this.valueFlagsDataSource = new MatTableDataSource<ValueFlag>(this.question.valueFlags);
      this.changeDetectorRef.detectChanges();
      this.setRangeType(+this.form.value.answerSelectionId);
    }
  }

  onAnswerTypeChanged() {
    this.setSecondaryAnswerTypes(+this.form.value.answerSelectionId)
    this.setRangeType(+this.form.value.answerSelectionId);
    this.setMinMaxValidators();
  }

  setSecondaryAnswerTypes(answerSelectionId: number) {
    this.secondaryAnswerSelections = [];

    if (answerSelectionId === 2) { // Text
      this.secondaryAnswerSelections.push({ id: 1, name: "text", description: "None" } as AnswerType);
      this.form.controls.secondaryAnswerSelectionId.setValue(1);
    }
    else if (answerSelectionId === 3) { // Integer
      this.secondaryAnswerSelections.push({ id: 3, name: "integer", description: "None" } as AnswerType);
      this.secondaryAnswerSelections.push({ id: 6, name: "inttext", description: "Text" } as AnswerType);
      if ([3, 6, 10].includes(this.form.controls.secondaryAnswerSelectionId.value) === false) {
        this.form.controls.secondaryAnswerSelectionId.setValue(3);
      }
    }
    else if (answerSelectionId === 4) { // Float
      this.secondaryAnswerSelections.push({ id: 4, name: "float", description: "None" } as AnswerType);
      this.secondaryAnswerSelections.push({ id: 7, name: "floattext", description: "Text" } as AnswerType);
      if ([4, 7].includes(this.form.controls.secondaryAnswerSelectionId.value) === false) {
        this.form.controls.secondaryAnswerSelectionId.setValue(4);
      }
    }
    else if (answerSelectionId === 5) { // File
      this.secondaryAnswerSelections.push({ id: 17, name: "fileupload", description: "None" } as AnswerType);
      this.secondaryAnswerSelections.push({ id: 18, name: "fileuploadtext", description: "Text" } as AnswerType);
      if ([17, 18].includes(this.form.controls.secondaryAnswerSelectionId.value) === false) {
        this.form.controls.secondaryAnswerSelectionId.setValue(17);
      }
    }
    else if (answerSelectionId === 6) { // Multiple choice
      this.secondaryAnswerSelections.push({ id: 13, name: "multichoicesingle", description: "Single Answer" } as AnswerType);
      this.secondaryAnswerSelections.push({ id: 15, name: "multichoicesingletext", description: "Single Answer with Text" } as AnswerType);
      this.secondaryAnswerSelections.push({ id: 14, name: "multichoicemulti", description: "Multiple Answers" } as AnswerType);
      this.secondaryAnswerSelections.push({ id: 16, name: "multichoicemultitext", description: "Multiple Answers with Text" } as AnswerType);
      if ([13, 14, 15, 16].includes(this.form.controls.secondaryAnswerSelectionId.value) === false) {
        this.form.controls.secondaryAnswerSelectionId.setValue(13);
      }
    }

    this.answerSelectionsLoaded = true;
  }

  setMinMaxValidators() {
    if (this.rangetype === 'Integer') {
      this.form.controls['answerminvalue'].setValidators([Validators.pattern(QuestionEditComponent.INTEGER_PATTERN)]);
      this.form.controls['answermaxvalue'].setValidators([Validators.pattern(QuestionEditComponent.INTEGER_PATTERN)]);
    }
    else if (this.rangetype === 'Float') {
      this.form.controls['answerminvalue'].setValidators([Validators.pattern(QuestionEditComponent.FLOAT_PATTERN)]);
      this.form.controls['answermaxvalue'].setValidators([Validators.pattern(QuestionEditComponent.FLOAT_PATTERN)]);
    }
    else {
      this.form.controls['answerminvalue'].setValidators(null);
      this.form.controls['answermaxvalue'].setValidators(null);
    }

    this.form.controls['answerminvalue'].updateValueAndValidity();
    this.form.controls['answermaxvalue'].updateValueAndValidity();
  }

  setRangeType(answerSelectionId: number) {
    this.rangetype = 'none';
    this.redflagtype = 'none';

    if (answerSelectionId === 3) { // integer
      this.rangetype = 'Integer';
    }

    if (answerSelectionId === 4) { // float
      this.rangetype = 'Float';
    }

    this.displayMultiChoice = answerSelectionId === 6;
    this.displayFollowUpQuestions = this.displayMultiChoice;

    this.displayrange = (this.rangetype !== 'none');
    this.displayValueFlags = (this.rangetype === 'Integer' || this.rangetype === 'Float');
  }

  private minMaxComparisonValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const start = fg.get('answerminvalue').value;
    const end = fg.get('answermaxvalue').value;
    if (start === null || end === null || start === '' || end === '') {
      return null;
    }
    else {
      return start < end ? null : { range: true };
    }
  };

  private minMaxRangeComparisonValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const answerMinimumValue = fg.get('answerminvalue').value;
    const answerMaximumValue = fg.get('answermaxvalue').value;
    const rangeErrors = {};

    this.question.valueFlags.forEach(flag => {
      if (flag.numericMaximumValue !== null && answerMaximumValue !== null) {
        if (flag.numericMaximumValue > answerMaximumValue) {
          rangeErrors['maxRangeLimit'] = true;
        }
      }
      else if (flag.numericMinimumValue !== null && answerMinimumValue) {
        if (flag.numericMinimumValue < answerMinimumValue) {
          rangeErrors['minRangeLimit'] = true;
        }
      }
    });

    return rangeErrors;
  };

  onSubmit() {
    // build a temporary question object from form values
    const question = {} as Question;
    question.id = this.question.id;
    question.text = this.form.value.text;
    question.safeText = this.sanitizer.bypassSecurityTrustHtml(this.form.value.text);
    question.description = this.form.value.description;
    question.displayOrder = this.question.displayOrder;
    question.categoryId = this.question.categoryId;
    question.parentQuestionId = this.question.parentQuestionId;
    question.weight = this.form.value.weight;
    if (this.parentQuestion && this.parentQuestion.answerType.startsWith('multichoice')) {
      const answerIds = [];
      for (let i = 0; i < this.form.value.multiChoiceMultiAnswer.length; i++) {
        if (this.form.value.multiChoiceMultiAnswer[i] === true) {
          answerIds.push(this.parentQuestion.multipleChoiceAnswers[i].id);
        }
      }
      question.parentFollowUpAnswer = JSON.stringify(answerIds);
    }
    else {
      question.parentFollowUpAnswer = String(this.form.value.parentQuestionAnswer);
    }

    const selectedAnswerType = this.secondaryAnswerSelections.find(a => a.id === +this.form.value.secondaryAnswerSelectionId);
    const answerType = this.answertypes.find(a => a.name === selectedAnswerType.name);
    question.answerTypeId = answerType.id;
    question.answerType = answerType.name;

    if (this.form.value.answerminvalue != null) {
      question.answerMinValue = this.form.value.answerminvalue;
    }
    else {
      question.answerMinValue = null;
    }

    if (this.form.value.answermaxvalue != null) {
      question.answerMaxValue = this.form.value.answermaxvalue;
    }
    else {
      question.answerMaxValue = null;
    }

    question.multipleChoiceAnswers = this.question.multipleChoiceAnswers;
    question.multipleChoiceAnswers.map(function (x) {
      x.displayOrder = question.multipleChoiceAnswers.findIndex(answer => answer.answerText === x.answerText) + 1;
      return x
    });

    question.followUpQuestions = this.question.followUpQuestions || [];
    question.followUpQuestions.map(function (x: Question) {
      x.displayOrder = question.followUpQuestions.findIndex(question => question.text === x.text) + 1;
      return x
    });

    question.valueFlags = this.question.valueFlags;

    const url = this.baseUrl + "api/question";

    if (this.followUpMode) {
      if (question.id) {
        this.http
          .post<Question>(url, question)
          .subscribe(res => {
            // success in updating the question
            console.log("Question " + res.id + " has been updated.");

            this.parentQuestion.followUpQuestions = this.parentQuestion.followUpQuestions.filter(x => x.id !== res.id);
            this.parentQuestion.followUpQuestions.push(res);
            this.parentQuestion.followUpQuestions = this.parentQuestion.followUpQuestions.sort((a, b) => a.displayOrder - b.displayOrder);

            ((document.getElementsByClassName("content"))[0]).scroll({
              top: -150,
              left: 0,
              behavior: 'auto'
            });

            this.popFollowUpMode();
            this.questionChange.emit(this.question);
            this.ngOnInit();
          }, error => console.log(error));
      }
      else {
        this.http
          .put<Question>(url, question)
          .subscribe(res => {
            // success in creating the new question
            console.log("Follow up question " + res.id + " has been created.");

            this.parentQuestion.followUpQuestions.push(res);

            ((document.getElementsByClassName("content"))[0]).scroll({
              top: -150,
              left: 0,
              behavior: 'auto'
            });

            this.popFollowUpMode();
            this.questionChange.emit(this.question);
            this.ngOnInit();
          }, error => console.log(error));
      }
    }
    else {
      if (this.editMode) {
        question.id = this.question.id;

        this.http
          .post<Question>(url, question)
          .subscribe(res => {
            // success in updating the question
            console.log("Question " + res.id + " has been updated.");
            this.questionChange.emit(this.question);
            this.editComplete.emit(1);
          }, error => console.log(error));
      }
      else {
        this.http
          .put<Question>(url, question)
          .subscribe(res => {
            // success in creating the new question
            console.log("Question " + res.id + " has been created.");

            this.createComplete.emit(res);
          }, error => {
            this.form.controls["text"].setErrors({ 'duplicate': true });
            console.log(error)
          });
      }
    }
  }

  onBack() {
    // Go back to the previous screen
    if (this.followUpMode) {
      ((document.getElementsByClassName("content"))[0]).scroll({
        top: -150,
        left: 0,
        behavior: 'auto'
      });

      this.popFollowUpMode();
      this.ngOnInit();
    }
    else {
      this.editComplete.emit(0);
    }
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onListDrop(event: CdkDragDrop<MultipleChoiceAnswer[]>) {
    const prevIndex = this.question.multipleChoiceAnswers.findIndex((d) => d === event.item.data);
    moveItemInArray(this.question.multipleChoiceAnswers, prevIndex, event.currentIndex);
    this.multipleChoiceAnswersDataSource = new MatTableDataSource<MultipleChoiceAnswer>(this.question.multipleChoiceAnswers);
  }

  public createAnswer() {
    const dialogConfig = new MatDialogConfig();
    const answer = {} as MultipleChoiceAnswer;
    answer.id = Math.floor(Math.random() * 10000) * -1;
    answer.questionId = this.question.id;
    answer.weight = null;

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      answer: answer,
    };

    const dialogRef = this.dialog.open(MultipleChoiceAnswerDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.answer) {
          if (this.question.multipleChoiceAnswers === null) {
            this.question.multipleChoiceAnswers = [];
          }

          this.question.multipleChoiceAnswers.push(data.answer);
          this.multipleChoiceAnswersDataSource = new MatTableDataSource<MultipleChoiceAnswer>(this.question.multipleChoiceAnswers);
        }
      });

    return false;
  }

  public editAnswer(answer) {
    const dialogConfig = new MatDialogConfig();
    const answerData = this.question.multipleChoiceAnswers.find(data => data.id === answer.id);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      answer: answerData,
    };

    const dialogRef = this.dialog.open(MultipleChoiceAnswerDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.answer) {
          const newAnswerData = this.question.multipleChoiceAnswers;
          const userIndexToEdit = this.question.multipleChoiceAnswers.findIndex(answers => answers.id === answer.id);
          this.question.multipleChoiceAnswers[userIndexToEdit] = data.answer;
          this.question.multipleChoiceAnswers = newAnswerData;
          this.question.multipleChoiceAnswers.forEach(mca => {
            mca.safeAnswerText = this.sanitizer.bypassSecurityTrustHtml(mca.answerText);
          });
          this.multipleChoiceAnswersDataSource = new MatTableDataSource<MultipleChoiceAnswer>(this.question.multipleChoiceAnswers);
        }
      });

    return false;
  }

  public deleteAnswer(answer) {
    let message = "Are you sure want to delete the following multiple choice answer?";
    message = message + answer.answerText;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const newAnswers = this.question.multipleChoiceAnswers.filter(data => data.id !== answer.id);
        this.question.multipleChoiceAnswers = newAnswers;
        this.multipleChoiceAnswersDataSource = new MatTableDataSource<MultipleChoiceAnswer>(this.question.multipleChoiceAnswers);
      }
    });

    return false;
  }

  onFollowUpQuestionsListDrop(event: CdkDragDrop<Question[]>) {
    const prevIndex = this.question.followUpQuestions.findIndex((d) => d === event.item.data);
    moveItemInArray(this.question.followUpQuestions, prevIndex, event.currentIndex);
    this.followUpQuestionsDataSource = new MatTableDataSource<Question>(this.question.followUpQuestions);
  }

  private pushFollowUpMode(followUpQuestion: Question) {
    this.parentQuestion = this.question;
    this.question = followUpQuestion;
  }

  private popFollowUpMode() {
    this.question = this.parentQuestion;
    this.parentQuestion = undefined;
  }

  public createFollowUpQuestion() {
    console.log("Add FollowUpQuestion has been selected.");
    const followUpQuestion = {} as Question;
    followUpQuestion.categoryId = this.question.categoryId;
    followUpQuestion.parentQuestionId = this.question.id;
    followUpQuestion.valueFlags = [];
    followUpQuestion.multipleChoiceAnswers = []

    this.pushFollowUpMode(followUpQuestion);
    this.ngOnInit();
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });  }

  public editFollowUpQuestion(followUpQuestion: Question) {
    console.log("Edit FollowUpQuestion " + followUpQuestion.id);

    this.pushFollowUpMode(followUpQuestion);
    this.ngOnInit();
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });  }

  public deleteFollowUpQuestion(followUpQuestion: Question) {
    let message = "Are you sure want to delete the follow-up question '";
    message = message + followUpQuestion.text;
    message = message + "'?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const url = this.baseUrl + "api/question/" + followUpQuestion.id;
        this.http.delete(url).subscribe(() => {
          const newFollowUpQuestions = this.question.followUpQuestions.filter(data => data.id !== followUpQuestion.id);
          this.question.followUpQuestions = newFollowUpQuestions;
          this.followUpQuestionsDataSource = new MatTableDataSource<Question>(this.question.followUpQuestions);
        }, error => console.error(error));
      }
    });

    return false;
  }

  public createValueFlag() {
    const dialogConfig = new MatDialogConfig();
    const flag = {} as ValueFlag;
    flag.id = 0;
    flag.questionId = this.question.id;

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      flag: flag,
      answerSelectionId: this.form.value.answerSelectionId,
    };

    const dialogRef = this.dialog.open(ValueFlagDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.flag) {
          if (this.question.valueFlags === null) {
            this.question.valueFlags = [];
          }

          this.question.valueFlags.push(data.flag);
          this.valueFlagsDataSource = new MatTableDataSource<ValueFlag>(this.question.valueFlags);
          this.form.controls['answerminvalue'].updateValueAndValidity();
        }
      });

    return false;
  }

  public editValueFlag(valueFlag: ValueFlag) {
    const dialogConfig = new MatDialogConfig();
    const flag = this.question.valueFlags.find(data => data.id === valueFlag.id);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      flag: flag,
      answerSelectionId: this.answertypes.filter(data => data.id === +this.form.value.answerSelectionId)[0].id,
    };

    const dialogRef = this.dialog.open(ValueFlagDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data !== null && data.flag !== null) {
          const newFlagData = this.question.valueFlags;
          const index = this.question.valueFlags.findIndex(flag => flag.id === valueFlag.id);
          this.question.valueFlags[index] = data.flag;
          this.question.valueFlags = newFlagData;
          this.valueFlagsDataSource = new MatTableDataSource<ValueFlag>(this.question.valueFlags);
          this.form.controls['answerminvalue'].updateValueAndValidity();
        }
      });

    return false;
  }

  public deleteValueFlag(valueFlag: ValueFlag) {
    let message = "Are you sure want to delete the ";
    if (valueFlag.valueFlagType === 1) {
      message = message + "green";
    }
    else if (valueFlag.valueFlagType === 2) {
      message = message + "yellow";
    }
    else if (valueFlag.valueFlagType === 3) {
      message = message + "red";
    }

    message = message + " value flag?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const newValueFlags = this.question.valueFlags.filter(data => data.id !== valueFlag.id);
        this.question.valueFlags = newValueFlags;
        this.valueFlagsDataSource = new MatTableDataSource<ValueFlag>(this.question.valueFlags);
      }
    });

    return false;
  }
}
