import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { AuthenticationService } from "../../services/authentication.service";
import { FileService } from "../../services/file.service";
import * as fileSaver from 'file-saver';
import { User } from '../../interfaces/user';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})

export class UserListComponent implements OnInit {

  displayedColumns: string[] = ['fullName', 'email', 'securityRole', 'organizationName', 'actions'];
  users: User[];
  datasource: MatTableDataSource<User>;
  filterCriteria: string;
  isAdminUser = false;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private fileService: FileService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const url = this.baseUrl + "api/user/All";
    this.http.get<User[]>(url).subscribe(result => {
      this.users = result;
      this.datasource = new MatTableDataSource<User>(this.users);
      this.datasource.sort = this.sort;
      this.datasource.sortingDataAccessor = (data, sortHeaderId) => {
        if (sortHeaderId !== 'organizationName' &&
          sortHeaderId !== 'fullName' &&
          !data[sortHeaderId]) {
          return this.sort.direction === "asc" ? '3' : '1';
        }
        switch (sortHeaderId) {
          case 'fullName': {
            return '2' + (data.firstName + " " + data.lastName).toLocaleLowerCase();
          }
          case 'securityRole': {
            return '2' + data.securityRole.name.toLocaleLowerCase();
          }
          case 'organizationName': {
            return '2' + data.organization.name.toLocaleLowerCase();
          }
          default: {
            return '2' + data[sortHeaderId].toLocaleLowerCase();
          }
        };      }
    },
      error => console.error(error));
  }

  isAdmin(user: User) {
    return user.securityRole.name === 'SysAdmin';
  }

  isCurrentUser(user: User) {
    return this.authenticationService.currentUserValue.id === user.id;
  }

  isDeletedUser(user: User) {
    return user.firstName === "Deleted" && user.lastName === "Account";
  }

  public editUser(user) {
    this.router.navigate(["/account/" + user.id], {
      queryParams: { u: user.id }
    });
  }

  public showSurveyResults(user: User) {
    this.router.navigate(["/survey/results/"], {
      queryParams: { u: user.email }
    });
  }

  public showUserActivity(user: User) {
    this.router.navigate(["/user/" + user.id + "/activity"]);
  }

  public downloadFiles(user: User) {
    this.fileService.downloadFile('api/upload/user/' + user.id).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/zip' });
        fileSaver.saveAs(blob, user.firstName + ' ' + user.lastName + '.zip');
      },
      error => {
        window.alert("There are no files to download for this user.");
        console.log('Error downloading the file: ' + error)
      }),
      () => console.info('File downloaded successfully');
  }

  public deleteUser(user) {
    let message = "Are you sure you want to delete ";
    message = message + user.firstName;
    message = message + " ";
    message = message + user.lastName;
    message = message + "?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Call the server to delete the user
        const url = this.baseUrl + "api/user/" + user.id;
        this.http.delete(url)
          .subscribe(
            data => {
              console.log("DELETE Request is successful ", data);

              // Update the grid
              const newUsers = this.users.filter(data => data.id !== user.id);
              this.users = newUsers;
              this.datasource = new MatTableDataSource<User>(this.users);
            },
            error => {
              console.log("Error", error);
            }
          );
      }
    });
  }

  exportUsers() {
    const jsonIds = JSON.stringify(this.users.map(x => x.id));

    this.fileService.downloadFile('api/user/export?criteria=' + encodeURIComponent(jsonIds)).subscribe(response => {
      const blob = new Blob([response], { type: 'application/zip' });
      fileSaver.saveAs(blob, 'users-export.zip');
    }), error => console.log('Error downloading the file: ' + error),
      () => console.info('File downloaded successfully');
  }

  applyFilter(filterValue: string) {
    this.filterCriteria = filterValue.trim().toLowerCase();
    this.datasource.filter = this.filterCriteria;
  }
}
