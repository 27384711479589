import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../custom-validators';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  securityStamp: string;
  message: string;
  resetPasswordForm: UntypedFormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  isReset: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    var id: string = this.activatedRoute.snapshot.paramMap.get("id");
    if (id) {
      this.securityStamp = id;
    }
    else {
      this.message = "An error occurred trying to get the password reset security token."
    }

    this.resetPasswordForm = this.formBuilder.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])],
      confirmPassword: ['', Validators.compose([Validators.required])],
    });
  }

  get f() { return this.resetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    var url = this.baseUrl + "api/user/password/";

    var data = {
      password: this.f.password.value,
      securityToken: this.securityStamp,
    }

    this.http.post(url, data).subscribe(result => {
      this.isReset = true;
      this.loading = false;
    }, error => {
      this.message = error;
      console.error(error);
      this.loading = false;
    });
  }
}
