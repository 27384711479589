import { Component, Inject, OnInit } from "@angular/core";
import { DynamicContent } from '../../interfaces/dynamicContent';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Message } from '../../interfaces/message';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { HttpClient } from "@angular/common/http";
import { RecaptchaErrorParameters } from "ng-recaptcha";

@Component({
  selector: "contact",
  templateUrl: "./contact.component.html"
})

export class ContactComponent {
  title = "Contact PAASP";
  dynamicContent: DynamicContent;
  safeDynamicContent: SafeHtml;
  form: UntypedFormGroup;
  messageSent = false;
  captchaComplete = false;
  submitted = false;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    @Inject('BASE_URL') private baseUrl: string
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      message: ['', Validators.required],
    });

    this.http.get<DynamicContent>(this.baseUrl + "api/dynamiccontent/name/Contact%20Page").subscribe(result => {
      this.dynamicContent = result;
      this.safeDynamicContent = this.sanitizer.bypassSecurityTrustHtml(this.dynamicContent.content);
    }, error => {
      console.error(error)
      this.dynamicContent = {} as DynamicContent;
      this.dynamicContent.content = "An error occurred attempting to load content for this page.";
    });
  }

  get f() { return this.form.controls; }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  onSend() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    let message = {} as Message;
    message.name = this.f.name.value;
    message.email = this.f.email.value;
    message.message = this.f.message.value;

    this.http.put(this.baseUrl + "api/message/", message)
      .subscribe(resp => {
        this.submitted = false;
        this.messageSent = true;
        this.captchaComplete = false;
        this.getFormControl("name").setValue("");
        this.getFormControl("email").setValue("");
        this.getFormControl("message").setValue("");
      },
        error => {
          this.submitted = false;
          console.log(error)
        });
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captchaComplete = true;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
}
