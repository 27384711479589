import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UserType } from '../../interfaces/usertype';

@Component({
  selector: 'user-type-details-dialog',
  templateUrl: './user-type-details-dialog.component.html',
  styleUrls: ['./user-type-details-dialog.component.css']
})

export class UserTypeDetailsDialogComponent implements OnInit {
  userType: UserType;
  form: UntypedFormGroup;
  title = 'Add';
  isSubmitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UserTypeDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    @Inject('BASE_URL') private baseUrl: string) {
    this.userType = data.userType;

    this.form = this.formBuilder.group({
      name: new UntypedFormControl(this.userType.name, Validators.required),
      descption: new UntypedFormControl(this.userType.description),
    });
  }

  ngOnInit() {
    if (this.userType.id) {
      this.title = 'Edit';
    }

    this.form = this.formBuilder.group({
      name: new UntypedFormControl(this.userType.name, Validators.required),
      description: new UntypedFormControl(this.userType.description),
    });
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };

    const dialogRef = this.dialog.open(UserTypeDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }

  close() {
    this.dialogRef.close();
  }

  getControls() {
    return (this.form.controls.flags as UntypedFormArray).controls;
  }

  save() {
    this.isSubmitted = true;
    const returnSettings = {
      name: this.form.value.name,
      description: this.form.value.description,
      id: this.userType.id,
    };

    this.dialogRef.close({
      settings: returnSettings
    });
  }
}
