import { Component, Inject, OnInit, SimpleChanges, ViewChild, HostListener } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from "../../services/authentication.service";
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Survey } from '../../interfaces/survey';
import { Module } from '../../interfaces/module';
import { Category } from '../../interfaces/category';
import { faEdit, faPlus, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SurveyService } from '../../services/survey.service';
import { SurveyStatusResult } from '../../interfaces/surveyStatusResult';
import { Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';

interface TreeFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: number;
}

export interface SurveyTreeNode {
  name: string;
  id: number;
  children?: SurveyTreeNode[];
}

@Component({
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})

export class SurveyComponent implements OnInit {
  private transformer = (node: SurveyTreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
    };
  }

  faPlus = faPlus;
  faEdit = faEdit;
  faCheck = faCheck;
  faTimes = faTimes;
  canEditSurveys = false;
  editMode = false;
  createMode = false;
  surveyVersionId: number;
  survey: Survey;
  modules: Module[];
  categories: Category[];
  selectedNodeType = "survey";
  selectedModule: Module;
  selectedCategory: Category;
  activeNode: TreeFlatNode;
  surveyStatusResult: SurveyStatusResult;
  treeControl = new FlatTreeControl<TreeFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.children);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: TreeFlatNode) => node.expandable;
  @ViewChild("scrollableArea") scrollableArea;
  isLoggedIn = false;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private surveyService: SurveyService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog,
    @Inject('BASE_URL') private baseUrl: string) {
    this.canEditSurveys = this.authenticationService.isAdmin() && this.authenticationService.hasFlag('a20f0ccb-e655-410c-9aaa-e272532c4d65')
    this.surveyService.completionUpdated$.subscribe(result => this.onCompletionUpdated(result));
    this.isLoggedIn = this.authenticationService.currentUserValue !== null;
  }

  @HostListener("document:click") clickOutsideSlideout() {
    // this.hideSidebar();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.surveyVersionId = +params['id'];
      console.log("assessment version selected: " + this.surveyVersionId);

      let url = this.baseUrl + "api/survey/surveyVersion/" + this.surveyVersionId;
      this.http.get<Survey>(url).subscribe(result => {
        this.survey = result;

        url = this.baseUrl + "api/module/surveyVersion/" + this.surveyVersionId;
        this.http.get<Module[]>(url).subscribe(result => {
          this.modules = result;

          url = this.baseUrl + "api/category/surveyVersion/" + this.surveyVersionId;
          this.http.get<Category[]>(url).subscribe(result => {
            this.categories = result;
            for(let i = 0; i < this.categories.length; i++) {
              if (i > 0) {
                this.categories[i].previous = this.categories[i-1].id;
              }
              if (i + 1 < this.categories.length) {
                this.categories[i].next = this.categories[i+1].id;
              }
            }

            if (this.deviceService.isDesktop()) {
              this.createTreeDatabase();
              this.selectFirstCategory();
            }

            const menuLeft = document.getElementById('cbp-spmenu-s1');
            menuLeft.classList.add('cbp-spmenu-open');
          },
            error => console.error(error));
        },
          error => console.error(error));
      },
        error => console.error(error));
    });
  }

  isAdmin() {
    return this.authenticationService.isAdmin();
  }

  onEdit() {
    this.editMode = true;
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  onCreateModule() {
    this.createMode = true;
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  onCreateStart(event: SimpleChanges) {
    this.createMode = true;
  }

  onCreateComplete(event: any) {
    this.createMode = false;
    if (event !== null) {
      if (this.activeNode.level === 0) {
        const module = event as Module;
        this.modules.push(module);
        this.createTreeDatabase();
        this.selectedModule = module;

        const newNode = this.treeControl.dataNodes.filter(x => x.level === 1 && x.id === module.id)[0];
        this.onNodeClick(newNode);
      }
      else if (this.activeNode.level === 1) {
        const category = event as Category;
        this.categories.push(category);
        this.createTreeDatabase();
        this.selectedCategory = category;

        const newNode = this.treeControl.dataNodes.filter(x => x.level === 2 && x.id === category.id)[0];
        this.onNodeClick(newNode);
      }
    }
  }

  onEditStart(event: SimpleChanges) {
    this.editMode = true;
  }

  onEditComplete(event: SimpleChanges) {
    if (this.activeNode.level === 0) {
      this.activeNode.name = this.survey.name;
    }
    else if (this.activeNode.level === 1) {
      this.activeNode.name = this.selectedModule.name;
    }
    else if (this.activeNode.level === 2) {
      this.activeNode.name = this.selectedCategory.name;
    }

    this.editMode = false;
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  onReorderComplete(event: number) {
    if (event === 1) {
      // Modules reordered
      const url = this.baseUrl + "api/module/surveyVersion/" + this.surveyVersionId;
      this.http.get<Module[]>(url).subscribe(result => {
        this.modules = result;
        this.createTreeDatabase();
        this.onSurveySelect(false);
      },
        error => console.error(error));
    }
    else if (event === 2) {
      const url = this.baseUrl + "api/category/surveyVersion/" + this.surveyVersionId;
      this.http.get<Category[]>(url).subscribe(result => {
        this.categories = result;

        this.createTreeDatabase();
        this.onModuleSelect(this.selectedModule, false);
      },
        error => console.error(error));
    }
  }

  onDelete(event: SimpleChanges) {
    if (this.activeNode.level === 0) {
      this.activeNode.name = this.survey.name;
    }
    else if (this.activeNode.level === 1) {
      this.modules = this.modules.filter(module => module.id !== this.activeNode.id);
      this.createTreeDatabase();

      this.onNodeClick(this.treeControl.dataNodes[0]);
    }
    else if (this.activeNode.level === 2) {
      const moduleId = this.categories.filter(category => category.id === this.activeNode.id)[0].moduleId;
      this.categories = this.categories.filter(category => category.id !== this.activeNode.id);
      this.createTreeDatabase();

      const parentNode = this.treeControl.dataNodes.filter(x => x.level === 1 && x.id === moduleId)[0];
      this.onNodeClick(parentNode);
    }
  }

  createTreeDatabase() {
    const treeData: SurveyTreeNode[] = []
    const surveyNode = {} as SurveyTreeNode;
    surveyNode.name = this.survey.name;
    surveyNode.id = this.survey.id;
    surveyNode.children = [];

    this.modules.forEach(module => {
      const moduleNode = {} as SurveyTreeNode;
      moduleNode.name = module.name;
      moduleNode.id = module.id;

      const categories = this.categories.filter(category => category.moduleId === module.id);

      moduleNode.children = categories.map(x => ({ name: x.name, id: x.id }));
      surveyNode.children.push(moduleNode);
    });

    treeData.push(surveyNode);
    this.dataSource.data = treeData;
  }

  selectFirstModule() {
    let firstModule: Module;
    if (this.modules && this.modules.length > 0 && !firstModule) {
      firstModule = this.modules[0];
    }

    if (firstModule) {
      this.onModuleSelect(firstModule, true);
    }
    else {
      this.onSurveySelect(true);
    }
  }

  selectFirstCategory() {
    let firstCategory: Category;
    this.modules.forEach(module => {
      const categories = this.categories.filter(category => category.moduleId === module.id);
      if (categories && categories.length > 0 && !firstCategory) {
        firstCategory = categories[0];
      }
    });

    if (firstCategory) {
      this.onCategorySelect(firstCategory);
      return;
    }

    this.selectFirstModule();
  }

  getParent(node: TreeFlatNode) {
    const currentLevel = this.treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

  onSurveySelect(click: boolean) {
    const surveys = this.treeControl.dataNodes.filter(x => x.level === 0 && x.id === this.survey.id);
    if (surveys.length > 0) {
      this.activeNode = surveys[0];

      if (click) {
        this.onNodeClick(this.activeNode);
      }
    }
  }

  onModuleSelect(module: Module, click: boolean) {
    const modules = this.treeControl.dataNodes.filter(x => x.level === 1 && x.id === module.id);
    if (modules.length > 0) {
      this.activeNode = modules[0];

      if (click) {
        this.onNodeClick(this.activeNode);
      }
    }
  }

  onCategorySelect(category: Category) {
    const categories = this.treeControl.dataNodes.filter(x => x.level === 2 && x.id === category.id);
    if (categories.length > 0) {
      this.activeNode = categories[0];
      this.onNodeClick(this.activeNode);
    }

    this.scrollableArea.nativeElement.scrollTop = 0;
  }

  onNodeClick(node: TreeFlatNode) {
    this.editMode = false;
    this.createMode = false;
    if (!node) {
      return;
    }

    this.treeControl.collapseAll();
    // this.hideSidebar();

    this.activeNode = node;
    if (node.level === 0) {
      this.selectedNodeType = 'survey';
      this.selectedModule = null;
      this.selectedCategory = null;
    }
    else if (node.level === 1) {
      this.selectedNodeType = 'module';
      this.selectedModule = this.modules.filter(x => x.id === node.id)[0];
      this.selectedCategory = null;
    }
    else if (node.level === 2) {
      this.selectedNodeType = 'category';
      this.selectedModule = null;
      this.selectedCategory = this.categories.filter(x => x.id === node.id)[0];
      this.scrollableArea.nativeElement.scrollTop = 0;
    }

    let parentNode = node;
    do {
      this.treeControl.expand(parentNode);
      parentNode = this.getParent(parentNode);
    } while (parentNode)
  }

  onPreviousCategory(event: SimpleChanges) {
    const index = this.categories.indexOf(this.selectedCategory);
    if (index - 1 < 0) {
      this.selectedCategory = this.categories[this.categories.length - 1];
    }
    else {
      this.selectedCategory = this.categories[index - 1];
    }

    this.onCategorySelect(this.selectedCategory);
  }

  onNextCategory(event: SimpleChanges) {
    const index = this.categories.indexOf(this.selectedCategory);
    if (index + 1 === this.categories.length) {
      this.selectedCategory = this.categories[0];
    }
    else {
      this.selectedCategory = this.categories[index + 1];
    }

    this.onCategorySelect(this.selectedCategory);
  }

  onCompletionUpdated(result: SurveyStatusResult) {
    this.surveyStatusResult = result;
  }

  isComplete(node: TreeFlatNode): boolean {
    if (this.surveyStatusResult) {
      if (node.level === 1 && this.surveyStatusResult.moduleStatus) {
        const modules = this.surveyStatusResult.moduleStatus.filter(x => x.id === node.id);
        if (modules && modules.length === 1) {
          const module = modules[0];
          return module.percentComplete >= 100;
        }
      }
      else if (node.level === 2 && this.surveyStatusResult.categoryStatus) {
        const categories = this.surveyStatusResult.categoryStatus.filter(x => x.id === node.id);
        if (categories && categories.length === 1) {
          const category = categories[0];
          return category.percentComplete >= 100;
        }
      }
    }

    return false
  }

  showSidebar(event) {
    event.stopPropagation();
    const menuLeft = document.getElementById('cbp-spmenu-s1');
    menuLeft.classList.add('cbp-spmenu-open');
  }

  hideSidebar() {
    const menuLeft = document.getElementById('cbp-spmenu-s1');
    menuLeft.classList.remove('cbp-spmenu-open');
  }

  onSubmit() {
    if (this.surveyStatusResult?.surveyStatus.percentComplete < 100.0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: "Your assessment is not yet complete. Please review the menu on the left to identify unanswered questions.",
          buttonText: {
            ok: 'Ok',
            cancel: '',
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {

        }
      });
    }
    else {
      // Submit and return to survey page.
      console.log("Submit assessment answers clicked.");
      // Call to update the answer set
      const url = this.baseUrl + "api/answerset";
      this.http.post(url, this.survey).subscribe(() => {
        this.router.navigate(["survey/summary"]);
      },
        error => console.error(error));
    }
  }
}
