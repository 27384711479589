import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { DynamicContent } from '../../interfaces/dynamicContent';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: "dynamic-content",
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.less']
})

export class DynamicContentComponent implements OnInit {
  dynamicContent: DynamicContent;
  form: UntypedFormGroup;
  isAdminUser = false;

  constructor(
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }

    this.dynamicContent = {} as DynamicContent;
    this.form = this.formBuilder.group({
      name: new UntypedFormControl('', Validators.required),
      content: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    const dynamicContentId = this.activatedRoute.snapshot.params["id"];

    // Get the role from the server.
    const url = this.baseUrl + "api/dynamiccontent/" + dynamicContentId;
    this.http.get<DynamicContent>(url).subscribe(result => {
      this.dynamicContent = result;

      this.form.setValue({
        name: result.name || '',
        content: result.content || '',
      });
    },
      error => console.error(error));
  }

  onSave() {
    let dynamicContent = {} as DynamicContent;
    dynamicContent.name = this.f.name.value;
    dynamicContent.content = this.f.content.value;
    dynamicContent.id = this.activatedRoute.snapshot.params["id"];

    this.http.put(this.baseUrl + "api/dynamiccontent/", dynamicContent)
      .subscribe(() => {
        this.router.navigate(['/dynamic-content']);
      },
        error => {
          console.log(error)
        });
  }

  onCancel() {
    this.router.navigate(['/dynamic-content']);
  }

  get f() { return this.form.controls; }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  hasFormError(name: string) {
    const e = this.form;
    return e && (e.dirty || e.touched) && !e.valid;
  }
}
