import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { SurveyStatusResult } from '../interfaces/surveyStatusResult';
import { SurveyResult } from '../interfaces/surveyResult';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SurveyService {
  public completionUpdated$: EventEmitter<SurveyStatusResult>;
  private subjectName = new Subject<any>();

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    this.completionUpdated$ = new EventEmitter();
  }

  public updateCompletion(surveyVersionId: number): void {
    if (surveyVersionId) {
      const url = this.baseUrl + "api/survey/" + surveyVersionId + "/status";
      this.http.get<SurveyStatusResult>(url).subscribe(result => {
        this.completionUpdated$.emit(result);
      },
        error => {
          console.error(error)
        });
    }
  }

  sendSurveyCompleteEvent(message: string) {
    this.subjectName.next({ text: message });
  }

  getSurveyCompleteEvent(): Observable<any> {
    return this.subjectName.asObservable();
  }

  public hasPastSurveys(userId: string) {
    if (userId) {
      const url = this.baseUrl + "api/survey/past/";
      return this.http.get<SurveyResult[]>(url)
        .pipe(map(result => {
          return result;
        }));
    }
  }
}
