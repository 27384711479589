import { Component, Inject, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { Category } from '../../interfaces/category';
import { DecimalValidationDirective } from '../directives/decimalValidation.directive';

@Component({
  selector: "category-edit",
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.less']
})

export class CategoryEditComponent {
  title: string;
  @Input() category: Category;
  @Input() moduleId: number;
  @Output() categoryChange = new EventEmitter<Category>();
  @Output() editComplete: EventEmitter<number> = new EventEmitter();
  @Output() createComplete: EventEmitter<Category> = new EventEmitter();
  categoryloaded = false;
  form: UntypedFormGroup;

  // this will be TRUE when editing an existing category, 
  //   FALSE when creating a new one.
  editMode: boolean;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {

    // initialize the form
    this.createForm();
  }

  ngOnInit() {
    if (this.category) {
      this.title = "Edit Category - " + this.category.name;
      this.categoryloaded = true;
      this.editMode = true;

      // update the form with the category value
      this.updateForm();
    }
    else {
      // create an empty object from the Category interface
      this.category = {} as Category;
      this.category.moduleId = this.moduleId;
      this.title = "Create a new Category";
    }
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: '',
      weight: [null, Validators.min(0)],
    });
  }

  updateForm() {
    this.form.setValue({
      name: this.category.name,
      description: this.category.description || '',
      weight: this.category.weight
    });
  }

  onSubmit() {
    //update the category object from form values
    this.category.name = this.form.value.name;
    this.category.description = this.form.value.description;
    this.category.weight = this.form.value.weight;

    const url = this.baseUrl + "api/category";

    if (this.editMode) {
      this.http
        .post<Category>(url, this.category)
        .subscribe(res => {
          this.category = res;
          this.categoryChange.emit(this.category);
          this.editComplete.emit(1);
          console.log("Category " + this.category.id + " has been updated.");
        },
          error => console.log(error));
    }
    else {
      this.http
        .put<Category>(url, this.category)
        .subscribe(res => {
          this.createComplete.emit(res);
          console.log("Category " + res.id + " has been created.");
        },
          error => console.log(error));
    }
  }

  onBack() {
    if (this.editMode) {
      this.editComplete.emit(0);
    }
    else {
      this.createComplete.emit(null);
    }
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }
}
