import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../services/authentication.service";
import { HttpClient } from "@angular/common/http";
import { AuditLogEntry } from "../../interfaces/auditLogEntry";
import { PagedAuditLogEntry } from "../../interfaces/pagedAuditLogEntry";
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { LocalizedDatePipe } from "../../app/helpers/localized-date.pipe";

@Component({
  selector: "audit-log-list",
  templateUrl: "./audit-log-list.component.html",
  styleUrls: ['./audit-log-list.component.css']
})

export class AuditLogListComponent implements OnInit {
  isAdminUser = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  auditLog: PagedAuditLogEntry;
  datasource: MatTableDataSource<AuditLogEntry>;
  displayedColumns: string[] = ['date', 'type', 'user', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) {
    this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }  }

  ngOnInit() {
    this.loadData();
    this.datasource.paginator = this.paginator;
  }

  loadData() {
    const url = this.baseUrl + "api/auditlog/?page=" + this.currentPage;
    this.http.get<PagedAuditLogEntry>(url).subscribe(result => {
      this.auditLog = result;
      this.totalRows = result.totalRows;
      this.datasource = new MatTableDataSource<AuditLogEntry>(this.auditLog.auditLogEntries);
    });
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.loadData();
  }
}
