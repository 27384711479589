import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { KeyValuePair } from '../../interfaces/keyValuePair';
import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'registration-validation',
  templateUrl: './registration-validation.component.html',
  styleUrls: ['./registration-validation.component.css']
})

export class RegistrationValidationComponent implements OnInit {
  requiredFields: string[];
  requiredValue: KeyValuePair;
  isAdminUser = false;
  form: UntypedFormGroup;

  constructor(
    private http: HttpClient,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
   this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }

    this.form = this.formBuilder.group({
      firstName: new UntypedFormControl(''),
      lastName: new UntypedFormControl(''),
      organization: new UntypedFormControl(''),
      targetAudience: new UntypedFormControl(''),
      department: new UntypedFormControl(''),
      position: new UntypedFormControl(''),
      userType: new UntypedFormControl(''),
      telephone: new UntypedFormControl(''),
      addressLine1: new UntypedFormControl(''),
      addressLine2: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
      zip: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
    });

  }

  ngOnInit() {
    this.http.get<KeyValuePair>(this.baseUrl + "api/keyvaluepair/name/registrationvalidation").subscribe(result => {
      this.requiredValue = result;
      this.requiredFields = result.value.split(",");
      this.requiredFields.forEach(key => {
        this.f[key].setValue(true);
      });
    },
      error => console.error(error));
  }

  get f() { return this.form.controls; }

  onSubmit() {
    let values = [];
    Object.keys(this.f).forEach(key => {
      if (this.f[key].value) {
        values.push(key);
      }
    });

    const value = {} as KeyValuePair;
    value.key = "registrationvalidation";
    value.value = values.join(',');

    this.http.put<KeyValuePair>(this.baseUrl + "api/keyvaluepair", value).subscribe(result => {
      this.router.navigate(['/admin']);
    },
      error => console.error(error));
  }

  onCancel() {
    this.router.navigate(['/admin']);
  }
}
