import { Component, Inject, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, ValidatorFn, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DomSanitizer } from '@angular/platform-browser'
import { Question } from '../../interfaces/question';
import { Answer } from '../../interfaces/answer';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: "answer-edit",
  templateUrl: './answer-edit.component.html',
  styleUrls: ['./answer-edit.component.less']
})

// this component is only loaded if a non-administrative user is being asked to answer a question
export class AnswerEditComponent implements OnInit {
  @Input() question: Question;
  @Output() answerChangedEvent = new EventEmitter<Question>();
  form: UntypedFormGroup;
  hasintegeranswer = false;
  hasfloatanswer = false;
  hastextanswer = false;
  numberlabel: string;
  numberplaceholder: string;
  textplaceholder: string;
  userId: string;
  isMultiChoiceSingleAnswer: boolean;
  isMultiChoiceMultiAnswer: boolean;
  isFileUploadAnswer: boolean;
  selectedFile: File = null;
  fileProgress = 0;
  progressbarValue = 0;
  error = '';
  submitMode = "save";
  loading = false;
  timer = null;
  isTextEmpty = true;
  isLoggedIn = false;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private authService: AuthenticationService,
    private sanitizer: DomSanitizer,
    @Inject('BASE_URL') private baseUrl: string) {
    if (this.authService.currentUserValue) {
      this.userId = this.authService.currentUserValue.id;
    }
    this.isLoggedIn = this.authService.currentUserValue !== null;
  }

  ngOnInit() {
    this.updateQuestion();
  }

  ngOnChanges() {
    this.updateQuestion();
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.progressbarValue = this.fileProgress;
    }, 5000);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  hasFileAnswer(answer: Answer): boolean {
    if (answer && answer.isExplicitlyNoFileAnswer) {
      return false;
    }

    return answer && answer.fileDescription && answer.fileDescription.length > 0;
  }

  updateQuestion() {
    if (!this.question.answer) {
      // create an empty object from the answer interface
      this.question.answer = {} as Answer;
      this.question.answer.userId = this.userId;
      this.question.answer.questionId = this.question.id;
    }

    // interpret the answer type
    this.hasintegeranswer = this.question.answerType.includes('int');
    this.hasfloatanswer = this.question.answerType.includes('float');
    this.hastextanswer = this.question.answerType.includes('text');
    this.isMultiChoiceSingleAnswer = this.question.answerType.indexOf('multichoicesingle') === 0;
    this.isMultiChoiceMultiAnswer = this.question.answerType.indexOf('multichoicemulti') === 0;
    this.isFileUploadAnswer = this.question.answerType.indexOf('fileupload') === 0;

    this.createForm();
  }
  
  minSelectedCheckboxes(min = 1) {
   const validator: ValidatorFn = (formArray: UntypedFormArray) => {
    const totalSelected = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);

    return totalSelected >= min ? null : { required: true };
  };

  return validator;
  }

  createForm() {
    const numberValidators: ValidatorFn[] = [];
    if (this.hasintegeranswer || this.hasfloatanswer) {
      numberValidators.push(Validators.required);
      const round = this.question.answerType.includes('float') ? 2 : 0;
      if (this.question.answerMinValue != null && this.question.answerMaxValue != null) {
        this.numberlabel = this.numberplaceholder = 'Value between ' + this.question.answerMinValue.toFixed(round) + ' and ' + this.question.answerMaxValue.toFixed(round);
        numberValidators.push(Validators.min(this.question.answerMinValue));
        numberValidators.push(Validators.max(this.question.answerMaxValue));
      }
      else if (this.question.answerMinValue != null) {
        numberValidators.push(Validators.min(this.question.answerMinValue));
        this.numberlabel = this.numberplaceholder = 'Value starting at ' + this.question.answerMinValue.toFixed(round);
      }
      else if (this.question.answerMaxValue != null) {
        this.numberlabel = this.numberplaceholder = 'Value up to ' + this.question.answerMaxValue.toFixed(round);
        numberValidators.push(Validators.max(this.question.answerMaxValue));
      }
      else {
        this.numberlabel = this.numberplaceholder = 'Value';
      }

      this.numberlabel += ':';
      this.numberplaceholder += '...';
    }
    if (this.hastextanswer) {
      if (this.question.answerType === 'text') {
        this.textplaceholder = 'Enter the answer here...'
        if (this.question.answer.textAnswer && this.question.answer.textAnswer.length > 0) {
          this.isTextEmpty = false;
        }
      }
      else {
        this.textplaceholder = 'Enter text here...'
        this.isTextEmpty = false;
      }
    }

    const mcsaValidators: ValidatorFn[] = [];
    if (this.isMultiChoiceSingleAnswer) {
      mcsaValidators.push(Validators.required);
    }

    const mcmaValidators: ValidatorFn[] = [];
    if (this.isMultiChoiceMultiAnswer) {
      mcmaValidators.push(this.minSelectedCheckboxes(1));
    }

    const multiCheckBoxes = this.question.multipleChoiceAnswers.map(() => new UntypedFormControl(false));
    if (this.isMultiChoiceMultiAnswer && this.question.answer && this.question.answer.multipleChoiceMultipleAnswers) {
      const selectedAnswers = this.question.answer.multipleChoiceMultipleAnswers.split(',');
      for (let i = 0; i < this.question.multipleChoiceAnswers.length; i++) {
        if (selectedAnswers.filter(a => a === this.question.multipleChoiceAnswers[i].id.toString()).length > 0) {
          multiCheckBoxes[i].setValue(true);
        }
      }
    }

    this.question.multipleChoiceAnswers.forEach(mca => {
      mca.safeAnswerText = this.sanitizer.bypassSecurityTrustHtml(mca.answerText);
    });

    // initialize the form
    this.form = this.fb.group({
      floatanswer: [this.question.answer.floatAnswer, numberValidators],
      integeranswer: [this.question.answer.intAnswer, numberValidators],
      textanswer: [this.question.answer.textAnswer],
      multiChoiceSingleAnswer: [this.question.answer.multipleChoiceSingleAnswer, mcsaValidators],
      multiChoiceMultiAnswer: new UntypedFormArray(multiCheckBoxes, mcmaValidators),
      file: [''],
      fileName: [this.question.answer.fileDescription],
      isNoFile: [this.question.answer.isExplicitlyNoFileAnswer],
    });

    if (this.isFileUploadAnswer) {
      if (this.question.answer.isExplicitlyNoFileAnswer) {
        this.form.controls["file"].disable();
      }

      this.form.setValidators(this.fileOrNoFile);
    }

    this.answerChangedEvent.emit(this.question);
  }

  saveAnswer() {
    this.loading = true;

    if (this.hastextanswer) {
      this.question.answer.textAnswer = this.form.value.textanswer;
    }
    if (this.question.answerType.includes('int')) {
      if (this.form.value.integeranswer) {
        this.question.answer.intAnswer = Math.round(+ this.form.value.integeranswer);
      }
      else {
        this.question.answer.intAnswer = null;
      }
    }
    if (this.question.answerType.includes('float')) {
      if (this.form.value.floatanswer) {
        this.question.answer.floatAnswer = + this.form.value.floatanswer;
      }
      else {
        this.question.answer.floatAnswer = null;
      }
    }
    if (this.isMultiChoiceSingleAnswer) {
      this.question.answer.multipleChoiceSingleAnswer = this.form.value.multiChoiceSingleAnswer;
    }
    if (this.isFileUploadAnswer) {
      this.question.answer.isExplicitlyNoFileAnswer = this.form.value.isNoFile;
    }
    if (this.isMultiChoiceMultiAnswer) {
      const selectedAnswerIds = this.form.value.multiChoiceMultiAnswer
        .map((v, i) => v ? this.question.multipleChoiceAnswers[i].id : null)
        .filter(v => v !== null);

      this.question.answer.multipleChoiceMultipleAnswers = selectedAnswerIds.toString();
    }
    if (this.isFileUploadAnswer && this.selectedFile !== null) {
      const formData = new FormData();
      for (const key in this.question.answer) {
        if (this.question.answer[key] !== null) {
          formData.append(key, this.question.answer[key]);
        }
      }

      formData.append('file', this.selectedFile);

      const url = this.baseUrl + "api/answer/file";

      if (this.question.answer.id) {
        if (this.hasFileAnswer(this.question.answer)) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              message: "Uploading a new file will delete the existing file. Are you sure you want to upload a new file?",
              buttonText: {
                ok: 'Yes',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.uploadFile(url, formData);
            }
            else {
              this.loading = false;
            }
          });
        }
        else {
          this.uploadFile(url, formData);
        }
      }
      else {
        this.http.put(url, formData, { reportProgress: true, observe: 'events' })
          .subscribe(resp => {
            if (resp.type === HttpEventType.Response) {
              // success in updating the answer
              this.loading = false;
              console.log("File answer has been created.");
              this.answerChangedEvent.emit(this.question);
            }
            else if (resp.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round(100 * resp.loaded / resp.total);
              if (percentDone > this.fileProgress) {
                this.fileProgress = percentDone;
              }
            }
          },
            error => {
              console.log(error)
              this.stopTimer();
            });
      }
    }
    else {
      const url = this.baseUrl + "api/answer";

      if (this.question.answer.id) {
        this.http
          .post<Answer>(url, this.question.answer)
          .subscribe(res => {
            // success in updating the answer
            console.log("Answer " + res.id + " has been updated.");
            this.loading = false;
            this.answerChangedEvent.emit(this.question);
          }, error => console.log(error));
      }
      else {
        this.http
          .put<Answer>(url, this.question.answer)
          .subscribe(res => {
            // success in creating the new answer
            console.log("Answer " + res.id + " has been created.");
            this.loading = false;
            this.question.answer.id = res.id;
            this.answerChangedEvent.emit(this.question);
          }, error => console.log(error));
      }
    }
  }

  uploadFile(url, formData) {
    this.fileProgress = 0;
    this.progressbarValue = 0;
    this.startTimer();

    this.http.post(url, formData, { reportProgress: true, observe: 'events' })
      .subscribe(resp => {
        if (resp.type === HttpEventType.Response) {
          // success in updating the answer
          this.loading = false;
          this.stopTimer();
          console.log("File answer has been updated.");
          this.question.answer.fileDescription = this.selectedFile.name;
          this.form.controls['fileName'].setValue(this.selectedFile.name);
          this.form.controls['file'].setValue(null);
        }
        if (resp.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * resp.loaded / resp.total);
          if (percentDone > this.fileProgress) {
            this.fileProgress = percentDone;
          }
          console.log('Progress ' + percentDone + '%');
        }
      },
        error => {
          console.log(error)
          this.stopTimer();
        });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  hasFormError(name: string) {
    const e = this.form;
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onAnswerChange(event) {
    this.saveAnswer();
  }

  onMultipleChoiceSingleAnswerChange(event: MatRadioChange) {
    this.question.answer.multipleChoiceSingleAnswer = event.value;
    this.form.value.multiChoiceSingleAnswer = event.value;
    this.saveAnswer();
  }

  onMultipleChoiceMultipleAnswerChange(event: MatCheckboxChange, id: number) {
    if (event.checked) {
      if (this.question.answer.multipleChoiceMultipleAnswers && this.question.answer.multipleChoiceMultipleAnswers.length > 0) {
        this.question.answer.multipleChoiceMultipleAnswers = this.question.answer.multipleChoiceMultipleAnswers + ',' + id;
      }
      else {
        this.question.answer.multipleChoiceMultipleAnswers = id.toString();
      }
    }
    else {
      const answers = '[' + this.question.answer.multipleChoiceMultipleAnswers + ']';
      const answerArray = JSON.parse(answers);
      this.question.answer.multipleChoiceMultipleAnswers = answerArray.filter(x => x !== id).toString();
    }
    this.saveAnswer();
  }

  onFileChange(event) {
    this.selectedFile = event.target.files[0] as File;
    this.saveAnswer();
  }

  onIsNoFileChange(event: MatCheckboxChange) {
    if (this.form.value.isNoFile) {
        this.form.get('file').setValue('');
        this.form.get('file').disable();
        this.form.controls['fileName'].setValue('');
        this.form.controls['file'].setValue(null);
        this.question.answer.fileDescription = '';
        this.question.answer.file = null;
        this.saveAnswer();
    }
    else {
      this.form.get('file').enable();
    }
  }

  public fileOrNoFile(fg: UntypedFormGroup) : ValidatorFn {
    const file = fg.get('file').value;
    const fileName = fg.get('fileName').value;
    const isNoFile = fg.get('isNoFile').value;
    const hasFile: boolean = file !== '' || fileName !== null;
    if (hasFile === false && isNoFile === false) {
      fg.get('file').setErrors({ required: true });
    }
    else if (hasFile === true && isNoFile === true) {
      fg.get('file').setErrors({ required: true });
    }
    return;
  }

  onFocusOutEvent(event: any) {
    if (this.question.answerType === 'text') {
      this.isTextEmpty = event.target.value.length === 0;
    }
    else {
      this.isTextEmpty = false;
    }
  }
}

