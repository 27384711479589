import { Component, Input, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { SurveyStatusResult } from '../../interfaces/surveyStatusResult';
import { SurveyService } from '../../services/survey.service';

@Component({
  selector: "survey-completion",
  templateUrl: './survey-completion.component.html',
  styleUrls: ['./survey-completion.component.css']
})

export class SurveyCompletionComponent {
  @Input()
  get surveyId(): number { return this.id; }
  set surveyId(id: number) {
    this.id = id || 0;
  }

  progressbarValue = 0;
  id: number;
  isAdminUser = false;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private surveyService: SurveyService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();
    this.surveyService.completionUpdated$.subscribe(result => this.onCompletionUpdated(result));
  }

  private onCompletionUpdated(result: SurveyStatusResult) {
    if (this.surveyId && this.isAdminUser === false) {
      if (this.progressbarValue !== result.surveyStatus.percentComplete) {
        this.progressbarValue = result.surveyStatus.percentComplete;
      }
    }
  }
}
