import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { SurveyService } from '../../services/survey.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent {
  isExpanded = false;
  checkedPastSurveys = false;
  showPastSurveys = false;
  private subscriptionName: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private surveyService: SurveyService,
  ) {
    this.subscriptionName= this.surveyService.getSurveyCompleteEvent().subscribe(() => {
      this.checkedPastSurveys = true;
    });
  }

  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  goToPAASP() {
    window.open("https://www.paasp.net", '_blank').focus();
  }

  isAdmin() {
    return this.authenticationService.isAdmin();
  }

  isLoggedIn() {
    return this.authenticationService.currentUserValue != null;
  }

  logout() {
    this.checkedPastSurveys = false;
  }

  currentUserId(): string {
    if (this.authenticationService.currentUserValue) {
      return this.authenticationService.currentUserValue.id;
    }
    else {
      return '';
    }
  }

  hasPastSurveys() {
    if (this.currentUserId().length > 0 && this.checkedPastSurveys == false) {
      this.checkedPastSurveys = true;
      this.surveyService.hasPastSurveys(this.currentUserId())
        .pipe(first())
        .subscribe(
          (result) => {
            this.showPastSurveys = result.length > 0;
            return this.showPastSurveys;
          },
          error => {
            return false;
          });
      }
  }
}
