import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../services/authentication.service";
import { HttpClient } from "@angular/common/http";
import { AuditLogEntry } from "../../interfaces/auditLogEntry";
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';

@Component({
  selector: "audit-log-detail",
  templateUrl: "./audit-log-detail.component.html",
  styleUrls: ['./audit-log-detail.component.css']
})

export class AuditLogDetailComponent implements OnInit {
  isAdminUser = false;
  isLoading = true;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  auditLog: AuditLogEntry;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) {
    this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const auditLogId = this.activatedRoute.snapshot.paramMap.get("id");
    const url = this.baseUrl + "api/auditlog/" + auditLogId;
    this.http.get<AuditLogEntry>(url).subscribe(result => {
      this.auditLog = result;
      this.isLoading = false;
    });
  }

  formatJSON(data: string) {
    if (data) {
      return JSON.stringify(JSON.parse(data), null, 2);;
    }
  }
}
