import { Component, Inject, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { Module } from '../../interfaces/module';
import { Survey } from '../../interfaces/survey';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "module",
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.less']
})

export class ModuleComponent {
  @Input() module: Module;
  @Input() survey: Survey;
  @Output() editStart: EventEmitter<number> = new EventEmitter();
  @Output() createStart: EventEmitter<number> = new EventEmitter();
  @Output() delete: EventEmitter<number> = new EventEmitter();
  canEditSurveys = false;
  faEdit = faEdit;
  faPlus = faPlus;
  faTrash = faTrash;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.canEditSurveys = this.authService.isAdmin() && this.authService.hasFlag('a20f0ccb-e655-410c-9aaa-e272532c4d65');
  }

  onEdit() {
    this.editStart.emit(1);
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  onDelete() {
    if (confirm("Do you really want to delete this module?")) {
      const url = this.baseUrl + "api/module/" + this.module.id;
      this.http
        .delete(url)
        .subscribe(() => {
          console.log("Module " + this.module.id + " has been deleted.");
          this.delete.emit(this.module.id);
        }, error => console.log(error));
    }
  }

  onCreateCategory() {
    this.createStart.emit(1);
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }
}
