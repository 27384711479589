import { Component, Inject, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { Category } from '../../interfaces/category';
import { User } from '../../interfaces/user';
import { Question } from '../../interfaces/question';
import { Survey } from '../../interfaces/survey';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from "@angular/material/legacy-slide-toggle";

@Component({
  selector: "category",
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.less']
})

export class CategoryComponent {
  @Input() category: Category;
  @Input() survey: Survey;
  @Output() previousCategoryEvent = new EventEmitter<Category>();
  @Output() nextCategoryEvent = new EventEmitter<Category>();
  @Output() editStart: EventEmitter<number> = new EventEmitter();
  @Output() delete: EventEmitter<number> = new EventEmitter();
  @Output() createStart: EventEmitter<number> = new EventEmitter();
  user: User;
  question: Question;
  canEditSurveys = false;
  questionMode = false;
  faEdit = faEdit;
  faTrash = faTrash;
  isDragDropMode = false;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.canEditSurveys = this.authService.isAdmin() && this.authService.hasFlag('a20f0ccb-e655-410c-9aaa-e272532c4d65');
  }

  onEdit() {
    this.editStart.emit(1);
    ((document.getElementsByClassName("content"))[0]).scroll({
      top: -150,
      left: 0,
      behavior: 'auto'
    });
  }

  onDelete() {
    if (confirm("Do you really want to delete this category?")) {
      const url = this.baseUrl + "api/category/" + this.category.id;
      this.http
        .delete(url)
        .subscribe(() => {
          console.log("Category " + this.category.id + " has been deleted.");
          this.delete.emit(this.category.id);
        }, error => console.log(error));
    }
  }

  onNextCategory(event: Category) {
    this.nextCategoryEvent.emit(this.category);
  }

  onPreviousCategory(event: Category) {
    this.previousCategoryEvent.emit(this.category);
  }

  onCreateQuestion(event: number) {
    this.questionMode = true;
  }

  onQuestionEdit(event: Question) {
    this.questionMode = true;
    this.question = event;
  }

  onQuestionEditComplete(event: number) {
    this.questionMode = false;
    this.question = null;
  }

  onQuestionCreated(event: Question) {
    this.questionMode = false;
    this.question = null;
  }

  toggleDragAndDropMode(event: MatSlideToggleChange) {
    this.isDragDropMode = event.checked;
  }
}
