import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuthenticationService } from "../../services/authentication.service";
import { FileService } from "../../services/file.service";
import { MatSort, Sort } from '@angular/material/sort';
import { UploadDialogComponent } from '../upload/upload-dialog.component';
import * as fileSaver from 'file-saver';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Upload } from '../../interfaces/upload';

@Component({
  selector: 'upload-list',
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.css']
})

export class UploadListComponent implements OnInit {
  showPostUploadMessage = false;
  displayedColumns: string[] = ['name', 'date', 'survey', 'actions'];
  uploads: Upload[];
  datasource: MatTableDataSource<Upload>;
  isAdminUser = false;
  filesloaded = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private fileService: FileService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isAdminUser = this.authenticationService.isAdmin();
  }

  ngOnInit() {
    const url = this.baseUrl + "api/upload";
    this.http.get<Upload[]>(url).subscribe(result => {
      this.uploads = result;
      this.datasource = new MatTableDataSource<Upload>(this.uploads);
      this.datasource.sort = this.sort;
      this.filesloaded = this.uploads && (this.uploads.length > 0);
    },
      error => console.error(error));
  }

  downloadUpload(upload: Upload) {
    this.fileService.downloadFile('api/upload/' + upload.id + '/file').subscribe(response => {
      const blob = new Blob([response], { type: upload.contentType });
      fileSaver.saveAs(blob, upload.name);
    }), error => {
      console.log('Error downloading the file: ' + error)
    },
      () => console.info('File downloaded successfully');
  }

  deleteUpload(upload: Upload) {
    let message = "Are you sure want to delete ";
    message = message + upload.name;
    message = message + "?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Call the server to delete the upload
        const url = this.baseUrl + "api/upload/" + upload.id;
        this.http.delete(url)
          .subscribe(
            data => {
              console.log("DELETE Request is successful ", data);

              // Update the grid
              const newUploads = this.uploads.filter(data => data.id !== upload.id);
              this.uploads = newUploads;
              this.datasource = new MatTableDataSource<Upload>(this.uploads);
            },
            error => {
              console.log("Error", error);
            }
          );
      }
    });
  }

  uploadFile() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      userId: this.authenticationService.currentUserValue.id,
    };

    const dialogRef = this.dialog.open(UploadDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          const upload = { } as Upload;

          upload.id = data.id;
          upload.name = data.name;
          upload.date = data.date;
          upload.answerSetName = data.answerSetName;

          // TODO: Once virus scanning is enabled again, delete this line
          upload.status = 2;

          this.uploads.push(upload);
          this.datasource = new MatTableDataSource<Upload>(this.uploads);

          // TODO: Once virus scanning is enabled again, set this variable to true
          this.showPostUploadMessage = false;
        }
      });
  }

  customSort(sort: Sort) {
    const data = this.uploads.slice();
    if (!sort.active || sort.direction === '') {
      this.datasource = new MatTableDataSource<Upload>(data);
      return;
    }
     this.datasource = new MatTableDataSource<Upload>(data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'date': return this.compare(a.date, b.date, isAsc);
        case 'survey': return this.compare(a.answerSetName, b.answerSetName, isAsc);
        default: return 0;
      }
    }));
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
