import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { SelectionModel } from '@angular/cdk/collections';
import { User } from '../../interfaces/user';
import { Survey } from '../../interfaces/survey';
import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: 'user-survey-access',
  templateUrl: './user-survey-access.component.html',
  styleUrls: ['./user-survey-access.component.css']
})

export class UserSurveyAccessComponent implements OnInit {
  displayedColumns: string[] = ['select', 'fullName', 'email'];
  users: User[];
  surveys: Survey[];
  filterCriteria: string;
  datasource: MatTableDataSource<User>;
  selection = new SelectionModel<User>(true, []);
  selected = 0;
  isAdminUser = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
   this.isAdminUser = this.authenticationService.isAdmin();

    if (!this.isAdminUser) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.http.get<User[]>(this.baseUrl + "api/user/All").subscribe(result => {
      this.users = result;
      this.datasource = new MatTableDataSource<User>(this.users);

      this.http.get<Survey[]>(this.baseUrl + "api/survey").subscribe(result => {
        this.surveys = result;
        if (this.surveys.length > 0 && this.selected === 0) {
          this.selected = this.surveys[0].id;
        }
        this.datasource.data.forEach(row => {
          const users = row.assignedSurveys.filter(x => x === this.selected);
          if (users.length > 0) {
            this.selection.select(row);
          }
        });
        this.datasource.filter = this.filterCriteria;
      },
        error => console.error(error));
    },
      error => console.error(error));
  }

  updateAssignment(userId: string, surveyId: number, state: number) {
    this.http.put(this.baseUrl + "api/user/" + userId + "/survey/" + surveyId.toString() + "/state/" + state.toString(), null).subscribe(() => {
      console.log("updated");
    },
      error => console.error(error));
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.datasource.data.length;
    return numSelected === numRows;
  }

  isAlwaysAvailable(): boolean {
    if (!this.surveys || !this.selected) {
      return false;
    }

    const survey: Survey = this.surveys.find(x => x.id == this.selected);

    return survey.isAlwaysAvailable;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.datasource.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.filterCriteria = filterValue.trim().toLowerCase();
    this.datasource.filter = this.filterCriteria;
  }

  onAssignmentChange(event: MatCheckboxChange, user: User) {
    this.updateAssignment(user.id, this.selected, 2);
  }

  onAllAssignmentChange(event: MatCheckboxChange) {
    this.users.forEach(user => {
      this.updateAssignment(user.id, this.selected, event.checked ? 1 : 0);
    });
  }

  onSurveyChange(event: MatSelectChange) {
    this.selected = event.value;
    this.refresh();
  }
}
