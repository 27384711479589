import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { OrganizationDetailsDialogComponent } from '../organization/organization-details-dialog.component';
import { OrganizationMergeDialogComponent } from '../organization/organization-merge-dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { faEdit, faPlus, faTrash, faBlender } from '@fortawesome/free-solid-svg-icons';
import { Organization } from '../../interfaces/organization';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.css']
})

export class OrganizationListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'description', 'actions'];
  organizations: Organization[];
  datasource: MatTableDataSource<Organization>;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faBlender = faBlender;
  filterCriteria = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const url = this.baseUrl + "api/organization";
    this.http.get<Organization[]>(url).subscribe(result => {
      this.organizations = result;
      this.datasource = new MatTableDataSource<Organization>(this.organizations);
      this.datasource.paginator = this.paginator;
      this.datasource.filter = this.filterCriteria;
      this.datasource.sort = this.sort;
      this.datasource.sortingDataAccessor = (data, sortHeaderId) => {
        return (data[sortHeaderId] == null ? '' : data[sortHeaderId]).toLocaleLowerCase();
      };
    },
      error => console.error(error));
  }

  public onCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      organization: {} as Organization,
    };

    const dialogRef = this.dialog.open(OrganizationDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          const url = this.baseUrl + "api/organization";
          this.http.post<Organization>(url, data.settings).subscribe(result => {
            this.organizations.push(result);
            this.datasource = new MatTableDataSource<Organization>(this.organizations);
          },
            error => console.error(error));
        }
      });
  }

  public editOrganization(organization: Organization) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      organization: organization,
    };

    const dialogRef = this.dialog.open(OrganizationDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          const url = this.baseUrl + "api/organization";
          this.http.put<Organization>(url, data.settings).subscribe(() => {
            const newOrganizations = this.organizations;
            const organizationIndexToEdit = this.organizations.findIndex(data => data.id === organization.id);
            this.organizations[organizationIndexToEdit] = data.settings;
            this.organizations = newOrganizations;
            this.datasource = new MatTableDataSource<Organization>(this.organizations);
          },
            error => console.error(error));
        }
      });
  }

  public mergeOrganization(organization) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      organization: organization,
      organizations: this.organizations,
    };

    const dialogRef = this.dialog.open(OrganizationMergeDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.settings) {
          const url = this.baseUrl + "api/organization/source/" + organization.id + "/target/" + data.settings.targetId;
          this.http.get(url, data.settings).subscribe(() => {
            // Update the grid
            const newOrganizations = this.organizations.filter(data => data.id !== organization.id);
            this.organizations = newOrganizations;
            this.datasource = new MatTableDataSource<Organization>(this.organizations);
          },
            error => console.error(error));
        }
      });
  }

  public deleteOrganization(organization) {
    let message = "Are you sure want to delete organization ";
    if (organization.name !== null && organization.name !== "") {
      message = message + organization.name;
    }
    message = message + "?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Call the server to delete the organization
        const url = this.baseUrl + "api/organization/" + organization.id;
        this.http.delete(url)
          .subscribe(
            data => {
              console.log("DELETE Request is successful ", data);

              // Update the grid
              const newOrganizations = this.organizations.filter(data => data.id !== organization.id);
              this.organizations = newOrganizations;
              this.datasource = new MatTableDataSource<Organization>(this.organizations);
            },
            error => {
              alert("Unable to delete the Organization. It may be in use. If so, use Merge.");
              console.log("Error", error);
            }
          );
      }
    });
  }

  applyFilter(filterValue: string) {
    this.filterCriteria = filterValue.trim().toLowerCase();
    this.datasource.filter = this.filterCriteria;
  }
}
