import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "invitation",
  templateUrl: "./invitation.component.html"
})

export class InvitationComponent implements OnInit {
  title = "Invite a Colleague";
  invitationForm: UntypedFormGroup;
  submitted = false;
  sent = false;
  error = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    @Inject('BASE_URL') private baseUrl: string) {
    // redirect to home if already logged in
    if (!this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.invitationForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.invitationForm.invalid) {
      return;
    }
    this.submitted = true;

    const url = this.baseUrl + "api/user/invitation";

    this.http
      .post(url, { emailAddress: this.invitationForm.controls.emailAddress.value })
      .subscribe(() => {
          console.log(this.invitationForm.controls.emailAddress.value + " has been invited.");
          this.sent = true;
          this.submitted = false;

        },
        error => {
          console.log(error);
          this.error = true;
          this.submitted = false;
        });
  }

  onEmailAddressChanged() {
    this.sent = false;
    this.submitted = false;
    this.error = false;
  }
}
